import firebase from "firebase";
import _ from "lodash";
import { SlateDoc } from "../../components/editor/Types";
import { FirebaseConfig } from "../FirebaseApp";
import { getMentionIds } from "../PostHelpers";
import { ChannelRecordConverter } from "./ChannelRecordConverter";
import { PostRecordConverter } from "./PostRecordConverter";
import { WELCOME_DOC } from "../../docs/Welcome";
import {
  ChannelId,
  ChannelRecord,
  PostId,
  PostRecord,
  PostRecords,
  UserId,
  UserPost,
  UserRecord,
} from "./Types";
import { UserRecordConverter } from "./UserRecordConverter";

export const POSTS_COLLECTION = "posts";
export const USERS_COLLECTION = "users";
export const CHANNELS_COLLECTION = "channels";
export const WHITELIST_COLLECTION = "whitelist";

export class FirestoreDatabase {
  firestore: firebase.firestore.Firestore;

  constructor(firestore: firebase.firestore.Firestore) {
    this.firestore = firestore;
  }

  static fromApp(app: firebase.app.App): Database {
    const firestore = firebase.firestore(app);
    const firebaseConfig = app.options as FirebaseConfig;
    if (firebaseConfig.appOptions?.mode === "emulator") {
      firestore.useEmulator("localhost", 8080);
    }
    const underlying = new FirestoreDatabase(firestore);
    return proxy(underlying);
  }

  static fromFirestore(firestore: firebase.firestore.Firestore): Database {
    const underlying = new FirestoreDatabase(firestore);
    return proxy(underlying);
  }

  async getPost(
    channelId: ChannelId,
    postId: PostId
  ): Promise<PostRecord | undefined> {
    console.debug(`getPost: ${postId} for channel ${channelId}`);

    const postDoc = await this.channelPostsCollection(channelId)
      .doc(postId)
      .withConverter(PostRecordConverter)
      .get();

    const postRecord = postDoc.data();
    if (!postRecord) {
      console.debug(
        `getPost: post ${postId} not found for channel ${channelId}`
      );
      return undefined;
    }

    return postRecord;
  }

  /**
   * Timing issues possible here:
   * 1. For single user, should never be > 1 updatePost call at the same
   *    time - the useAutoSave hook plus react lifecycle rules make that
   *    unlikely. But could be that the hook gets unloaded and reloaded
   *    and the earlier write is delayed.
   * 2. This working correctly depends on the caller invoking these calls
   *    in the right order. If we can't count on that then we need to pass
   *    a timestamp (sequence id) in.
   */
  async updatePost(
    channelId: ChannelId,
    postId: PostId,
    postRecord: Partial<PostRecord>
  ): Promise<void> {
    console.debug(`updatePost: ${postId} for channel ${channelId}`);
    const newPostRecord = {
      ...postRecord,
      updatedAt: new Date(),
    };
    if (postRecord.body) {
      const mentionIds = getMentionIds(postRecord.body);
      newPostRecord.mentions = mentionIds;
    }
    return this.channelPostsCollection(channelId)
      .doc(postId)
      .update(newPostRecord);
  }

  async deleteThreadPost(
    channelId: ChannelId,
    postId: PostId,
    parentId: PostId
  ): Promise<void> {
    console.debug(`deletePost: ${postId} for channel ${channelId}`);

    if (parentId) {
      const parentPost = await this.getPost(channelId, parentId);
      if (!parentPost) {
        throw new Error(`Could not find parent post; parent=${parentId}`);
      }
      const threadPosts = parentPost.threadPosts.filter((id) => id !== postId);
      await this.updatePost(channelId, parentId, { threadPosts });
    }

    await this.deletePost(channelId, postId, []);
  }

  async deletePost(
    channelId: ChannelId,
    postId: PostId,
    threadPosts: PostId[]
  ): Promise<void> {
    console.debug(`deletePost: ${postId} for channel ${channelId}`);

    await this.channelPostsCollection(channelId).doc(postId).delete();

    if (threadPosts.length > 0) {
      console.debug(
        `deletePost: deleting ${threadPosts.length} thread posts for post ${postId}`
      );
      const results = threadPosts.map((threadPostId) =>
        this.deletePost(channelId, threadPostId, [])
      );
      await Promise.all(results);
    }

    console.debug(`deletePost: done; postId=${postId}`);
  }

  async createPost(
    channelId: ChannelId,
    userId: UserId,
    postId: PostId,
    body: SlateDoc,
    title: string
  ) {
    const newPostRecord = PostRecords.mainPostWithBody(
      channelId,
      postId,
      userId,
      body,
      title
    );

    return this.createPostFromRecord(newPostRecord);
  }

  async createPostFromRecord(postRecord: PostRecord): Promise<void> {
    console.debug(
      `createPostFromRecord: ${postRecord.id} for user ${postRecord.authorId}`
    );

    await this.channelPostsCollection(postRecord.channelId)
      .withConverter(PostRecordConverter)
      .doc(postRecord.id)
      .set(postRecord);

    console.debug(
      `createPostFromRecord: ${postRecord.id} for user ${postRecord.authorId} done`
    );
  }

  async getUser(uid: UserId): Promise<UserRecord | undefined> {
    console.debug(`getUser: ${uid}`);
    const userDoc = await this.firestore
      .collection(USERS_COLLECTION)
      .withConverter(UserRecordConverter)
      .doc(uid)
      .get();

    return userDoc.exists ? userDoc.data()! : undefined;
  }

  async getChannel(channelId: ChannelId): Promise<ChannelRecord | undefined> {
    console.debug(`getChannel: ${channelId}`);
    const channelDoc = await this.channelsCollection()
      .withConverter(ChannelRecordConverter)
      .doc(channelId)
      .get();

    return channelDoc.exists ? channelDoc.data()! : undefined;
  }

  async getMentionPosts(
    channelId: ChannelId,
    postId: PostId
  ): Promise<PostRecord[]> {
    const postsDoc = await this.channelPostsCollection(channelId)
      .where("mentions", "array-contains", postId)
      .orderBy("updatedAt", "desc")
      .withConverter(PostRecordConverter)
      .get();

    const posts = postsDoc.docs.map((doc) => {
      return doc.data();
    });

    return posts;
  }

  async getMentionUserPosts(
    channelId: ChannelId,
    postId: PostId
  ): Promise<UserPost[]> {
    console.debug(
      `getMentionUserPosts: fetching mentions for id ${postId} in channel ${channelId}`
    );
    const posts = await this.getMentionPosts(channelId, postId);
    return this.getUserPostsForPosts(posts);
  }

  async getUserPostsForPosts(posts: PostRecord[]): Promise<UserPost[]> {
    const postUserIds: UserId[] = [];
    posts.forEach((post) => {
      postUserIds.push(post.authorId);
    });
    const users = await this.getUsersForIds(postUserIds);

    return posts.map((post) => {
      const user = users.find((u) => u.uid === post.authorId);
      if (!user) {
        throw new Error("Missing user for post!");
      }
      return {
        user: user,
        post: post,
      };
    });
  }

  async getUsersForIds(userIds: UserId[]): Promise<UserRecord[]> {
    const uniqueIds = _.uniq(userIds);
    console.debug(`getUsersForIds: fetching users for ids ${uniqueIds}`);
    if (!userIds || userIds.length === 0) {
      return [];
    }
    const userDoc = await this.firestore
      .collection(USERS_COLLECTION)
      .where("uid", "in", uniqueIds)
      .withConverter(UserRecordConverter)
      .get();

    return userDoc.docs.map((doc) => {
      return doc.data();
    });
  }

  async getOrInitializeUser(
    userId: UserId,
    displayName: string,
    userName: string
  ): Promise<UserRecord | undefined> {
    console.debug(`getOrInitializeUser: for user ${userId}`);

    const userDocRef = await this.firestore
      .collection(USERS_COLLECTION)
      .withConverter(UserRecordConverter)
      .doc(userId);

    const channelDocRef = await this.firestore
      .collection(CHANNELS_COLLECTION)
      .withConverter(ChannelRecordConverter)
      .doc();

    const postDocRef = await this.firestore
      .collection(CHANNELS_COLLECTION)
      .doc(channelDocRef.id)
      .collection(POSTS_COLLECTION)
      .withConverter(PostRecordConverter)
      .doc();

    const userRecord: UserRecord = {
      uid: userId,
      displayName: displayName,
      username: userName,
      mainChannelId: channelDocRef.id,
    };

    const channelRecord: ChannelRecord = {
      id: channelDocRef.id,
      name: "main",
      updatedAt: new Date(),
      owners: [userRecord.uid],
      visibility: "public",
      theme: "pink",
    };

    const postRecord = PostRecords.mainPostWithBody(
      channelDocRef.id,
      postDocRef.id,
      userId,
      WELCOME_DOC,
      "Welcome to Reweave"
    );

    const [created, userDoc] = await this.firestore.runTransaction(
      async (tx) => {
        const doc = await tx.get(userDocRef);
        if (!doc.exists) {
          await tx.set(userDocRef, userRecord);
          await tx.set(channelDocRef, channelRecord);
          // Sadly it seems like this doesn't work - maybe not ok to span nested collections.
          //await tx.set(postDocRef, postRecord);
          console.debug(
            `getOrCreateUser: done creating new user record for user ${userId}`
          );
          return [true, doc];
        } else {
          return [false, doc];
        }
      }
    );

    // If created = false, we cant use any of the data created above (records etc) since we
    // didnt end up using it.
    if (created) {
      await postDocRef.set(postRecord);
      return this.getUser(userId);
    } else {
      return userDoc.data();
    }
  }

  async getUserPosts(
    userId: UserId,
    options?: { includeThreadPosts: boolean }
  ): Promise<PostRecord[]> {
    console.debug(`getUserPosts: fetching user posts for user ${userId}`);

    let postsDoc = await this.postsCollection(userId)
      .orderBy("updatedAt", "desc")
      .withConverter(PostRecordConverter)
      .get();

    return postsDoc.docs.map((doc) => {
      return doc.data();
    });
  }

  async getChannelPosts(
    channelId: UserId,
    updatedAfter: Date = new Date()
  ): Promise<UserPost[]> {
    console.debug(
      `getChannelPosts: fetching posts for channel ${channelId}, updatedAfter=${updatedAfter}`
    );

    let postsDoc = await this.channelPostsCollection(channelId)
      .where("parentId", "==", null)
      .orderBy("updatedAt", "desc")
      .startAfter(updatedAfter)
      .limit(30)
      .withConverter(PostRecordConverter)
      .get();

    const posts = postsDoc.docs.map((doc) => {
      return doc.data();
    });

    return this.getUserPostsForPosts(posts);
  }

  async getAllPostsByTitlePrefix(
    channelId: ChannelId,
    titlePrefix: string
  ): Promise<UserPost[]> {
    console.debug(
      `getAllPostsByTitlePrefix: fetching posts for title ${titlePrefix} in channel ${channelId}`
    );
    const postsDoc = await this.channelPostsCollection(channelId)
      .where("title", ">=", titlePrefix)
      .where("title", "<", `${titlePrefix}\uf8ff`)
      .limit(10)
      .withConverter(PostRecordConverter)
      .get();

    const posts = postsDoc.docs.map((doc) => {
      return doc.data();
    });

    return this.getUserPostsForPosts(posts);
  }

  async getUserChannels(userId: UserId): Promise<ChannelRecord[]> {
    console.debug(`getUserChannels for ${userId}`);

    const channelsDoc = await this.channelsCollection()
      .where("owners", "array-contains", userId)
      .withConverter(ChannelRecordConverter)
      .get();

    return channelsDoc.docs.map((doc) => {
      return doc.data();
    });
  }

  async getUserChannel(
    userId: UserId,
    channelName: string
  ): Promise<ChannelRecord | undefined> {
    console.debug(`getUserChannel for ${userId} and ${channelName}`);

    const channelsDoc = await this.channelsCollection()
      .where("owners", "array-contains", userId)
      .where("name", "==", channelName)
      .limit(1)
      .withConverter(ChannelRecordConverter)
      .get();

    const recs = channelsDoc.docs.map((doc) => {
      return doc.data();
    });

    return recs.length > 0 ? recs[0] : undefined;
  }

  async getWhitelisted(email: string): Promise<{ result: boolean }> {
    console.debug(`getWhitelisted for ${email}`);

    try {
      const whitelistDoc = await this.whitelistCollection().doc(email).get();
      console.debug(`Whitelisted: ${whitelistDoc.exists}`);
      return { result: whitelistDoc.exists };
    } catch (error) {
      console.debug(`Whitelist error: ${error}`);
      return { result: false };
    }
  }

  /**
   * Query helpers
   */
  private postsCollection(uid: UserId) {
    return this.firestore
      .collection(USERS_COLLECTION)
      .doc(uid)
      .collection(POSTS_COLLECTION);
  }
  private channelPostsCollection(channelId: ChannelId) {
    return this.firestore
      .collection(CHANNELS_COLLECTION)
      .doc(channelId)
      .collection(POSTS_COLLECTION);
  }
  private channelsCollection() {
    return this.firestore.collection(CHANNELS_COLLECTION);
  }
  private whitelistCollection() {
    return this.firestore.collection(WHITELIST_COLLECTION);
  }
}

export type Database = Pick<
  FirestoreDatabase,
  | "createPostFromRecord"
  | "createPost"
  | "deletePost"
  | "deleteThreadPost"
  | "getPost"
  | "getChannel"
  | "getUser"
  | "getMentionUserPosts"
  | "getOrInitializeUser"
  | "getUserPosts"
  | "getChannelPosts"
  | "getAllPostsByTitlePrefix"
  | "getUserChannels"
  | "getUserChannel"
  | "getWhitelisted"
  | "updatePost"
>;

const proxy = (database: Database): Database => {
  return {
    getPost: async (channelId: ChannelId, postId: PostId) =>
      database.getPost(channelId, postId),
    getChannel: async (channelId: ChannelId) => database.getChannel(channelId),
    updatePost: async (
      channelId: ChannelId,
      postId: PostId,
      postRecord: Partial<PostRecord>
    ) => database.updatePost(channelId, postId, postRecord),
    deletePost: async (uid: UserId, postId: PostId, threadPosts: PostId[]) =>
      database.deletePost(uid, postId, threadPosts),
    deleteThreadPost: async (
      userId: UserId,
      postId: PostId,
      parentId: PostId
    ) => database.deleteThreadPost(userId, postId, parentId),
    createPostFromRecord: async (postRecord: PostRecord) =>
      database.createPostFromRecord(postRecord),
    getUser: async (uid: UserId) => database.getUser(uid),
    getMentionUserPosts: async (channelId: ChannelId, postId: PostId) =>
      database.getMentionUserPosts(channelId, postId),
    getOrInitializeUser: async (
      userId: UserId,
      displayName: string,
      userName: string
    ) => database.getOrInitializeUser(userId, displayName, userName),
    getUserPosts: async (
      userId: UserId,
      options?: { includeThreadPosts: boolean }
    ) => database.getUserPosts(userId, options),
    getChannelPosts: async (channelId: UserId, updatedAfter: Date) =>
      database.getChannelPosts(channelId, updatedAfter),
    getAllPostsByTitlePrefix: async (
      channelId: ChannelId,
      titlePrefix: string
    ) => database.getAllPostsByTitlePrefix(channelId, titlePrefix),
    createPost: async (
      channelId: ChannelId,
      userId: UserId,
      postId: PostId,
      body: SlateDoc,
      title: string
    ) => database.createPost(channelId, userId, postId, body, title),
    getUserChannels: async (userId: UserId) => database.getUserChannels(userId),
    getUserChannel: async (userId: UserId, channelName: string) =>
      database.getUserChannel(userId, channelName),
    getWhitelisted: async (email: string) => database.getWhitelisted(email),
  };
};
