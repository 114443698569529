import { Box, makeStyles, Typography } from "@material-ui/core";
import { Link } from "./Link";

export const WaitlistedPage = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Typography variant="h3" paragraph>
        Thanks for signing up!
      </Typography>
      <Typography variant="h4" paragraph>
        Reweave is a tool for building ideas in public.
      </Typography>
      <Typography variant="h4" paragraph>
        You've been added to our waitlist.
      </Typography>
      <Typography variant="h4" paragraph>
        More info: <Link to="mailto:info@rockfish.xyz">info@rockfish.xyz</Link>
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  textField: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));
