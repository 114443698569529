import { useState, useCallback } from "react";

export const useMenuState = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const onMenuClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setMenuAnchorEl(event.currentTarget);
    },
    []
  );
  const onMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);
  return { menuAnchorEl, onMenuClick, onMenuClose };
};
