import { Typography } from "@material-ui/core";
import { PropsWithChildren } from "react";
import { useGlobalStyles } from "../../styles/GlobalStyles";

export const Blockquote = (
  props: PropsWithChildren<{
    className?: string;
  }>
) => {
  const classes = useGlobalStyles();

  let className = classes.blockquote;
  if (props.className) {
    className = props.className;
  }

  return (
    <blockquote className={`${className} ${classes.alwaysBreakWord}`}>
      <Typography variant="body1" paragraph>
        {props.children}
      </Typography>
    </blockquote>
  );
};
