import firebase from "firebase";
import { useCallback, useEffect, useState } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useParams,
} from "react-router-dom";
import { SWRConfig } from "swr";
import { Database } from "../services/database/FirestoreDatabase";
import { UserRecord } from "../services/database/Types";
// import { importAllFromUserToChannel } from "../services/ImportTool";
import { OpenGraph } from "../services/OpenGraph";
import { Paths } from "../services/Paths";
import { AuthdRoute } from "./AuthdRoute";
import { ChannelView, ChannelViewParams } from "./ChannelView";
import { PeripheralViewContainer } from "./PeripheralViewContainer";
import { PostView, PostViewParams } from "./PostView";
import { PostPreviewView, PostPreviewViewParams } from "./PostPreviewView";
import { SignInView } from "./SignInView";
import { SignUpView } from "./SignUpView";
import { Breadcrumb, ViewContainer } from "./ViewContainer";
import { WaitlistedPage } from "./WaitlistedPage";

export const AppContainer = ({
  loggedInUser,
  firebaseAuth,
  database,
  opengraph,
}: {
  loggedInUser: UserRecord | undefined;
  firebaseAuth: firebase.auth.Auth;
  database: Database;
  opengraph: OpenGraph;
}) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const onBreadcrumbsChange = useCallback((breadcrumbs: Breadcrumb[]) => {
    setBreadcrumbs(breadcrumbs);
  }, []);
  const loggedIn = !!loggedInUser;

  return (
    <SWRConfig value={{ revalidateOnFocus: false }}>
      <BrowserRouter>
        <Switch>
          <Route path={Paths.post(":channelId", ":postId")} exact>
            <PostViewWithParams
              loggedInUser={loggedInUser}
              database={database}
              onBreadcrumbsChange={onBreadcrumbsChange}
              opengraph={opengraph}
              breadcrumbs={breadcrumbs}
            />
          </Route>
          <Route path={Paths.postPreview(":channelId", ":postId")} exact>
            <PostPreviewViewWithParams database={database} />
          </Route>
          <AuthdRoute
            path={Paths.channel(":channelId")}
            exact
            loggedIn={loggedIn}
          >
            <ChannelViewWithParams
              loggedInUser={loggedInUser!}
              database={database}
              onBreadcrumbsChange={onBreadcrumbsChange}
              opengraph={opengraph}
              breadcrumbs={breadcrumbs}
            />
          </AuthdRoute>
          <AuthdRoute path={Paths.channel()} exact loggedIn={loggedIn}>
            <ChannelViewWithoutParams loggedInUser={loggedInUser!} />
          </AuthdRoute>
          <Route path={Paths.landingPage()}>
            <RedirectExternal to="https://signup.reweave.xyz" />
          </Route>
          <Route path={Paths.waitlisted()}>
            <PeripheralViewContainer>
              <WaitlistedPage />
            </PeripheralViewContainer>
          </Route>
          <Route path={Paths.login()} exact>
            <PeripheralViewContainer>
              <SignInView
                firebaseAuth={firebaseAuth}
                loggedInUser={loggedInUser}
                database={database}
              />
            </PeripheralViewContainer>
          </Route>
          <Route path={Paths.signup()} exact>
            <PeripheralViewContainer>
              <SignUpView
                firebaseAuth={firebaseAuth}
                loggedInUser={loggedInUser}
                database={database}
              />
            </PeripheralViewContainer>
          </Route>
          <Route path={Paths.logout()}>
            <LogoutAndRedirect firebaseAuth={firebaseAuth} />
          </Route>
          {/* <Route path="/migrate">
            <Migrate database={database}/>
          </Route> */}
          <Route path="*">
            <Redirect to={Paths.landingPage()} />
          </Route>
        </Switch>
      </BrowserRouter>
    </SWRConfig>
  );
};

const RedirectExternal = ({ to }: { to: string }) => {
  window.location.replace(to);
  return <></>;
};

const LogoutAndRedirect = ({
  firebaseAuth,
}: {
  firebaseAuth: firebase.auth.Auth;
}) => {
  console.debug("logging out ...");
  useEffect(() => {
    const f = async () => {
      await firebaseAuth.signOut();
    };
    f();
  });

  return <Redirect to={Paths.login()} />;
};

const PostViewWithParams = ({
  loggedInUser,
  database,
  onBreadcrumbsChange,
  breadcrumbs,
  opengraph,
}: {
  loggedInUser: UserRecord | undefined;
  database: Database;
  onBreadcrumbsChange: (breadcrumbs: Breadcrumb[]) => void;
  breadcrumbs: Breadcrumb[];
  opengraph: OpenGraph;
}) => {
  const { channelId, postId } = useParams<PostViewParams>();
  return (
    <ViewContainer
      loggedInUser={loggedInUser}
      channelId={channelId}
      database={database}
      opengraph={opengraph}
      breadcrumbs={breadcrumbs}
    >
      <PostView
        database={database}
        channelId={channelId}
        postId={postId}
        loggedInUser={loggedInUser}
        onBreadcrumbsChange={onBreadcrumbsChange}
        key={`post_view_${postId}`}
      />
    </ViewContainer>
  );
};

const PostPreviewViewWithParams = ({ database }: { database: Database }) => {
  const { channelId, postId } = useParams<PostPreviewViewParams>();
  return (
    <PostPreviewView
      database={database}
      channelId={channelId}
      postId={postId}
      key={`post_preview_view_${postId}`}
    />
  );
};

const ChannelViewWithParams = ({
  loggedInUser,
  database,
  onBreadcrumbsChange,
  breadcrumbs,
  opengraph,
}: {
  loggedInUser: UserRecord;
  database: Database;
  onBreadcrumbsChange: (breadcrumbs: Breadcrumb[]) => void;
  breadcrumbs: Breadcrumb[];
  opengraph: OpenGraph;
}) => {
  const { channelId } = useParams<ChannelViewParams>();
  return (
    <ViewContainer
      loggedInUser={loggedInUser}
      database={database}
      channelId={channelId}
      opengraph={opengraph}
      breadcrumbs={breadcrumbs}
    >
      <ChannelView
        database={database}
        channelId={channelId}
        onBreadcrumbsChange={onBreadcrumbsChange}
      />
    </ViewContainer>
  );
};

const ChannelViewWithoutParams = ({
  loggedInUser,
}: {
  loggedInUser: UserRecord;
}) => {
  const channelId = loggedInUser?.mainChannelId;
  if (!channelId) {
    return <Redirect to={Paths.waitlist()} />;
  } else {
    return <Redirect to={Paths.channel(loggedInUser!.mainChannelId)} />;
  }
};

// const Migrate = ({database}: any) => {
//   importAllFromUserToChannel(database, "OKnqhTRAB9X383HsprpnY1MuLWd2", "Rlzu26TopeZKWDL1honV");
//   return <>Importing...</>;
// };
