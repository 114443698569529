import { Link as MuiLink } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { PropsWithChildren } from "react";

export const Link = ({ to, children }: PropsWithChildren<{ to: string }>) => {
  return (
    <MuiLink color="primary" to={to} component={RouterLink}>
      {children}
    </MuiLink>
  );
};
