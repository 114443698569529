import {
  Avatar,
  createStyles,
  CssBaseline,
  Grid,
  makeStyles,
  MuiThemeProvider,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import useSWR from "swr";
import { Database } from "../services/database/FirestoreDatabase";
import { ChannelId, PostId } from "../services/database/Types";
import { SwrPaths } from "../services/SwrPaths";
import { previewTheme } from "../styles/Theme";
import { DelayLoadWrapper } from "./DelayLoadWrapper";
import { Editor } from "./editor/Editor";
import { SlateDoc } from "./editor/Types";

export const PostPreviewView = ({
  channelId,
  postId,
  database,
}: {
  channelId: ChannelId;
  postId: PostId;
  database: Pick<
    Database,
    | "getPost"
    | "updatePost"
    | "getUser"
    | "getChannel"
    | "createPostFromRecord"
    | "deletePost"
    | "deleteThreadPost"
    | "getMentionUserPosts"
    | "createPost"
    | "getAllPostsByTitlePrefix"
    | "getUserChannel"
  >;
}) => {
  const channelResult = useSWR(SwrPaths.channel(channelId), async () =>
    database.getChannel(channelId)
  );

  const postResult = useSWR(
    SwrPaths.post(channelId, postId),
    async () => database.getPost(channelId, postId),
    { revalidateOnMount: true }
  );

  const userResult = useSWR(
    () => {
      if (!postResult.data) {
        throw new Error("post not ready, cant fetch user");
      }
      return [
        SwrPaths.user(postResult.data.authorId),
        postResult.data.authorId,
      ];
    },
    async (path, authorId) => {
      return database.getUser(authorId);
    }
  );

  return (
    <DelayLoadWrapper
      ready={!!userResult.data && !!postResult.data && !!channelResult.data}
      error={userResult.error || postResult.error || channelResult.error}
      render={() => {
        return (
          <MuiThemeProvider theme={previewTheme}>
            <CssBaseline />
            <PreviewCard
              body={postResult.data!.body}
              title={postResult.data!.title}
            />
          </MuiThemeProvider>
        );
      }}
    />
  );
};

export type PostPreviewViewParams = {
  channelId: ChannelId;
  postId: PostId;
};

const Badge = ({ label, count }: { label: String; count: number }) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.badgeCount}>
          {count}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.badgeLabel}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

const PreviewCard = ({
  body,
  title,
}: {
  body: SlateDoc;
  title: String | undefined;
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={11}>
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    variant="h1"
                    style={{
                      width: "900px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {title ?? ""}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Avatar src="/logo192.png" className={classes.avatar} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="body1" component="p" className={classes.body}>
            <div className={classes.fade}></div>
            <Editor
              body={body}
              readOnly={true}
              onChange={(value: SlateDoc) => {}}
            />
          </Typography>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              <Badge label={"Mentions"} count={12} />
            </Grid>
            <Grid item>
              <Badge label={"Topics"} count={9} />
            </Grid>
            <Grid item>
              <Badge label={"Replies"} count={14} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const bodyHeightPx = 280;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1200,
      maxHeight: 600,
      padding: theme.spacing(6),
    },
    body: {
      height: `${bodyHeightPx}px`,
      position: "relative",
      overflowY: "hidden",
    },
    badgeCount: {},
    badgeLabel: {
      fontWeight: 300,
    },
    fade: {
      content: '""',
      width: "100%",
      height: `${bodyHeightPx}px`,
      position: "absolute",
      left: "0",
      top: "0",
      background: "linear-gradient(transparent 80%, white)",
    },
    cardGridSpacingLeft: {
      paddingLeft: theme.spacing(2),
    },
    cardGridSpacing: {
      padding: theme.spacing(2),
    },
    avatar: {
      width: "96px",
      height: "96px",
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    cardActions: {
      paddingLeft: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
  })
);
