import firebase from "firebase";
import { ChannelRecord } from "./Types";

export const ChannelRecordConverter = {
  toFirestore: (ur: ChannelRecord): firebase.firestore.DocumentData => {
    return ur;
  },
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): ChannelRecord => {
    const data = snapshot.data(options)!;
    return {
      id: data.id,
      name: data.name,
      owners: data.owners,
      visibility: data.visibility,
      updatedAt: data.updatedAt,
      theme: data.theme || "default",
    };
  },
};