import { Box, Divider, makeStyles } from "@material-ui/core";
import _ from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import useSWR, { mutate } from "swr";
import { Database } from "../services/database/FirestoreDatabase";
import {
  ChannelId,
  ChannelRecord,
  idgen,
  PostId,
  PostRecord,
  PostRecords,
  UserPost,
  UserRecord,
} from "../services/database/Types";
import { postPreviewStringFromStart } from "../services/PostHelpers";
import { SwrPaths } from "../services/SwrPaths";
import { DelayLoadWrapper } from "./DelayLoadWrapper";
import { MentionsSection } from "./MentionsSection";
import { PostActions } from "./PostActions";
import { PostBody } from "./PostBody";
import { PostHeader } from "./PostHeader";
import { ThreadSection } from "./ThreadSection";
import { useBreadcrumbs } from "./useBreadcrumbs";
import { Breadcrumb } from "./ViewContainer";

export const PostView = ({
  channelId,
  postId,
  database,
  loggedInUser,
  onBreadcrumbsChange,
}: {
  channelId: ChannelId;
  postId: PostId;
  loggedInUser: UserRecord | undefined;
  database: Pick<
    Database,
    | "getPost"
    | "updatePost"
    | "getUser"
    | "getChannel"
    | "createPostFromRecord"
    | "deletePost"
    | "deleteThreadPost"
    | "getMentionUserPosts"
    | "createPost"
    | "getAllPostsByTitlePrefix"
    | "getUserChannel"
  >;
  onBreadcrumbsChange: (breadcrumbs: Breadcrumb[]) => void;
}) => {
  const channelResult = useSWR(SwrPaths.channel(channelId), async () =>
    database.getChannel(channelId)
  );
  const postResult = useSWR(
    SwrPaths.post(channelId, postId),
    async () => database.getPost(channelId, postId),
    { revalidateOnMount: true }
  );

  useEffect(() => {
    // Hack due to a) cache is losing an update and b) revalidate on
    // mount not working
    mutate(SwrPaths.post(channelId, postId));
  }, [channelId, postId]);

  const userResult = useSWR(
    () => {
      if (!postResult.data) {
        throw new Error("post not ready, cant fetch user");
      }
      return [
        SwrPaths.user(postResult.data.authorId),
        postResult.data.authorId,
      ];
    },
    async (path, authorId) => {
      return database.getUser(authorId);
    }
  );

  const updatePost = useCallback(
    async (
      channelId: ChannelId,
      postId: PostId,
      postRecord: Partial<PostRecord>
    ) => {
      const postPath = SwrPaths.post(channelId, postId);
      mutate(
        postPath,
        async (prevRecord: PostRecord) => {
          return { ...prevRecord, ...postRecord };
        },
        false
      );
      await database.updatePost(channelId, postId, postRecord);
      mutate(postPath);
    },
    [database]
  );

  const deletePost = async (
    channelId: ChannelId,
    postId: PostId,
    threadPosts: PostId[]
  ) => {
    threadPosts.forEach((threadPostId) => {
      mutate(SwrPaths.post(channelId, threadPostId), undefined, false);
    });
    const postPath = SwrPaths.post(channelId, postId);
    mutate(postPath, undefined, false);
    await database.deletePost(channelId, postId, threadPosts);
    mutate(postPath);
  };

  const states = useRef<IsDirtyFn[]>([]);

  const registerState = (postId: PostId, registeredFn: IsDirtyFn) => {
    if (states.current.find((fn) => fn === registeredFn)) {
      console.warn(`IsDirtyFn already exists for postId ${postId}`);
    } else {
      console.debug(`Registered for postId ${postId}`);
      states.current.push(registeredFn);
    }
    return () => {
      console.debug(`Deregistered for postId ${postId}`);
      states.current = states.current.filter((fn) => fn !== registeredFn);
    };
  };

  return (
    <DelayLoadWrapper
      ready={!!userResult.data && !!postResult.data && !!channelResult.data}
      error={userResult.error || postResult.error || channelResult.error}
      render={() => {
        if (postResult.data?.parentId || postResult.data?.title === "IGNORE") {
          return (
            <ThreadPostView
              userRecord={userResult.data!}
              postRecord={postResult.data!}
              loggedInUser={loggedInUser}
              channel={channelResult.data!}
              database={{ ...database, deletePost, updatePost }}
              registerState={registerState}
              onBreadcrumbsChange={onBreadcrumbsChange}
              key={`thread_post_${postResult.data!.id}`}
            />
          );
        } else {
          return (
            <MainPostView
              userRecord={userResult.data!}
              postRecord={postResult.data!}
              loggedInUser={loggedInUser}
              channel={channelResult.data!}
              database={{ ...database, deletePost, updatePost }}
              registerState={registerState}
              onBreadcrumbsChange={onBreadcrumbsChange}
              key={`main_post_${postResult.data!.id}`}
            />
          );
        }
      }}
    />
  );
};

const MainPostView = ({
  database,
  registerState,
  postRecord,
  userRecord,
  loggedInUser,
  channel,
  onBreadcrumbsChange,
}: {
  postRecord: PostRecord;
  userRecord: UserRecord;
  loggedInUser: UserRecord | undefined;
  channel: ChannelRecord;
  database: Pick<
    Database,
    | "updatePost"
    | "createPost"
    | "getAllPostsByTitlePrefix"
    | "getPost"
    | "deletePost"
    | "getMentionUserPosts"
    | "createPostFromRecord"
    | "deleteThreadPost"
  >;
  registerState: (postId: PostId, registeredFn: IsDirtyFn) => () => void;
  onBreadcrumbsChange: (breadcrumbs: Breadcrumb[]) => void;
}) => {
  const classes = useStyles();
  useBreadcrumbs(channel, postRecord, undefined, onBreadcrumbsChange);
  const postId = postRecord.id!;
  const userId = postRecord.authorId;
  const mentionsResult = useSWR(
    SwrPaths.postMentions(channel.id, postId),
    async () => database.getMentionUserPosts(channel.id, postId)
  );
  const mentionPosts = mentionsResult.data ? mentionsResult.data : [];
  const readOnly = !(loggedInUser && loggedInUser.uid === userId);

  const onAddThreadPost = useCallback(async () => {
    const newThreadPostId = idgen();
    const threadPostRecord = PostRecords.emptyThreadPost(
      userId,
      newThreadPostId,
      postId,
      channel.id
    );

    // Note: If this failed it would be left in the cache.
    const threadPostPath = SwrPaths.post(channel.id, newThreadPostId);
    mutate(threadPostPath, threadPostRecord, false);
    await database.createPostFromRecord(threadPostRecord);
    mutate(threadPostPath);

    database.updatePost(channel.id, postId, {
      threadPosts: [...postRecord.threadPosts, newThreadPostId],
    });
  }, [channel, userId, postId, database, postRecord]);

  const deleteThreadPost = async (
    channelId: ChannelId,
    postId: PostId,
    parentId: PostId
  ) => {
    const parentPostPath = SwrPaths.post(channelId, parentId);
    mutate(
      parentPostPath,
      async (prevPost: PostRecord) => {
        const newThreadPosts = prevPost.threadPosts.filter(
          (id) => id !== postId
        );
        const recUpdate = {
          ...prevPost,
          threadPosts: newThreadPosts,
        };
        return recUpdate;
      },
      false
    );
    await database.deleteThreadPost(channelId, postId, parentId);
    mutate(parentPostPath);
  };

  return (
    <>
      <PostViewMetaTags postRecord={postRecord} mentionPosts={mentionPosts} />
      <Box className={classes.postComponentsBox}>
        <PostHeader
          postRecord={postRecord}
          userRecord={userRecord}
          loggedInUser={loggedInUser}
          database={database}
        />
        <PostBody
          key={`post_body_${postRecord.id!}`}
          userRecord={userRecord}
          postRecord={postRecord}
          loggedInUser={loggedInUser}
          channel={channel}
          database={database}
          registerState={registerState}
        />
        <ThreadSection
          userRecord={userRecord}
          threadPosts={postRecord.threadPosts}
          channel={channel}
          loggedInUser={loggedInUser}
          database={{ ...database, deleteThreadPost }}
          registerState={registerState}
        />
        {!readOnly && (
          <PostActions
            addButtonDisabled={false}
            onAddThreadPost={onAddThreadPost}
          />
        )}
        {mentionPosts.length > 0 && (
          <>
            <Divider className={classes.divider} />
            <MentionsSection
              mentionedPost={postRecord}
              mentionPosts={mentionPosts}
              channelId={channel.id}
              database={database}
            />
          </>
        )}
      </Box>
    </>
  );
};

const ThreadPostView = ({
  database,
  registerState,
  postRecord,
  userRecord,
  loggedInUser,
  channel,
  onBreadcrumbsChange,
}: {
  postRecord: PostRecord;
  userRecord: UserRecord;
  loggedInUser: UserRecord | undefined;
  channel: ChannelRecord;
  database: Pick<
    Database,
    | "updatePost"
    | "createPost"
    | "getAllPostsByTitlePrefix"
    | "getPost"
    | "deletePost"
  >;
  registerState: (postId: PostId, registeredFn: IsDirtyFn) => () => void;
  onBreadcrumbsChange: (breadcrumbs: Breadcrumb[]) => void;
}) => {
  const classes = useStyles();
  const parentPostResult = useSWR(
    SwrPaths.post(channel.id, postRecord.parentId!),
    async () => {
      if (postRecord.parentId) {
        return database.getPost(channel.id, postRecord.parentId);
      } else {
        return undefined;
      }
    }
  );

  useBreadcrumbs(
    channel,
    postRecord && postRecord.title !== "IGNORE" ? postRecord : undefined,
    parentPostResult.data,
    onBreadcrumbsChange
  );

  return (
    <DelayLoadWrapper
      ready={!!parentPostResult.data || !postRecord.parentId}
      error={!!parentPostResult.error}
      render={() => {
        const title =
          parentPostResult.data?.title ||
          postPreviewStringFromStart(postRecord.body, 2000);
        return (
          <>
            <PostViewMetaTags
              postRecord={{
                ...postRecord,
                title: title,
              }}
            />
            <Box className={classes.postComponentsBox}>
              <PostHeader
                postRecord={postRecord}
                userRecord={userRecord}
                loggedInUser={loggedInUser}
                database={database}
              />
              <PostBody
                key={`post_body_${postRecord.id!}`}
                userRecord={userRecord}
                postRecord={postRecord}
                channel={channel}
                loggedInUser={loggedInUser}
                database={database}
                registerState={registerState}
              />
            </Box>
          </>
        );
      }}
    />
  );
};

const PostViewMetaTags = ({
  postRecord,
  mentionPosts,
}: {
  postRecord: PostRecord;
  mentionPosts?: UserPost[];
}) => {
  const postPreview = useCallback(
    (postRecord: PostRecord) => {
      const preview = postPreviewStringFromStart(postRecord.body, 2000);
      const nonThreadMentionPosts =
        mentionPosts && mentionPosts.filter((up) => up.post.title !== "IGNORE");
      if (preview === "") {
        if (nonThreadMentionPosts && nonThreadMentionPosts.length > 0) {
          return `Explore connections to ${nonThreadMentionPosts
            .map((m) => m.post.title)
            .join(", ")}`;
        } else {
          return `Empty post for ${postRecord.title}`;
        }
      } else {
        return preview;
      }
    },
    [mentionPosts]
  );
  const truncatedTitle = _.truncate(postRecord.title, {
    length: 30,
    separator: /\s/,
  });
  const pageTitle = `${truncatedTitle} | Reweave`;
  const description = postPreview(postRecord);
  const largeSummary = postRecord.title.length < 27 && !postRecord.parentId;
  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta property="og:title" content={pageTitle} />
      <meta
        name="twitter:card"
        content={largeSummary ? "summary_large_image" : "summary"}
      ></meta>
      <meta name="twitter:title" content={postRecord.title}></meta>
      {largeSummary && (
        <meta
          name="twitter:image"
          content={`https://image.thum.io/get/viewportWidth/1200/width/1200/crop/600/https://reweave.xyz/post-preview/${
            postRecord.channelId
          }/${postRecord.id!}`}
        ></meta>
      )}
      <meta name="description" content={description}></meta>
      <meta name="twitter:description" content={description}></meta>
    </Helmet>
  );
};

export type PostViewParams = {
  channelId: ChannelId;
  postId: PostId;
};

export type IsDirtyFn = () => boolean;

const useStyles = makeStyles((theme) => ({
  card: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  postComponentsBox: { marginBottom: theme.spacing(4) },
}));
