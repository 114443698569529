import { useEffect } from "react";
import { ChannelRecord, PostRecord } from "../services/database/Types";
import { Paths } from "../services/Paths";
import { Breadcrumb } from "./ViewContainer";

export const useBreadcrumbs = (
  channel: ChannelRecord | undefined,
  postRecord: PostRecord | undefined,
  parentPostRecord: PostRecord | undefined,
  onBreadcrumbsChange: (breadcrumbs: Breadcrumb[]) => void
) => {
  const postTitle = postRecord?.title;
  const postId = postRecord?.id!;
  const channelId = postRecord?.channelId;
  const parentPostTitle = parentPostRecord?.title;
  const parentPostId = postRecord?.parentId;

  useEffect(() => {
    if (!channel) {
      return;
    }
    const bcs: Breadcrumb[] = [{
      text: `#${channel.name}`,
      href: Paths.channel(channel.id),
      type: "channel"
    }];
    if (parentPostId) {
      if (parentPostTitle && channelId && postId) {
        bcs.push({
          text: parentPostTitle,
          href: Paths.postReply(channelId, parentPostId, postId),
          type: "post",
        });
      }
    } else if (postTitle && channelId && postId) {
      bcs.push({
        text: postTitle,
        href: Paths.post(channelId, postId),
        type: "post",
      });
    }
    onBreadcrumbsChange(bcs);
  }, [
    postId,
    channelId,
    parentPostTitle,
    parentPostId,
    postTitle,
    channel,
    onBreadcrumbsChange,
  ]);
};
