import { ELEMENT_MENTION } from "@blfrg.xyz/slate-plugins";
import { createEditor, Editor, Node, Path, Text } from "slate";
import { PostId, UserPost } from "./database/Types";
import { SlateDoc } from "../components/editor/Types";
import { wrapNodesAsDoc, isLastBlock } from "../components/editor/Utils";

export type MentionContext = {
  post: UserPost;
  text: SlateDoc;
  path: Path;
  truncatedStart: boolean;
  truncatedEnd: boolean;
};

export const postPreviewFromStart = (
  body: SlateDoc,
  maxChars?: number
): [SlateDoc, boolean, boolean] => {
  return postPreview(body, [0, 0, 0], maxChars);
};

export const mentionPreview = (
  body: SlateDoc,
  path: Path,
  maxChars?: number
): [SlateDoc, boolean, boolean] => {
  return postPreview(body, path, maxChars);
};

export const nodePreviewString = (node: Node): string => {
  if (Text.isText(node)) {
    return node.text;
  } else if ("type" in node && node.type === "mention") {
    return node.value as string;
  } else {
    return node.children
      .map(nodePreviewString)
      .map((s) => s.trim())
      .filter((s) => !s.match(/^\s*$/))
      .join(" ");
  }
};

export const postPreviewStringFromStart = (
  body: SlateDoc,
  maxLen: number
): string => {
  if (maxLen < 2) {
    return "";
  }
  const [previewDoc, , truncatedByPreview] = postPreviewFromStart(body, maxLen);
  let previewString = nodePreviewString(previewDoc[0]);
  let truncatedByMaxLen = false;
  const elipsisSuffix = " ⋯";
  const maxLenWithoutSuffix = maxLen - elipsisSuffix.length;
  if (previewString.length > maxLenWithoutSuffix) {
    previewString = previewString.substring(0, maxLenWithoutSuffix);
    truncatedByMaxLen = true;
  }

  return (
    previewString +
    (truncatedByPreview || truncatedByMaxLen ? elipsisSuffix : "")
  );
};

/**
 * V2 postPreview function, still very hacky. This only attempts to pull a
 * little more context if its *easy*. Basically it will look for the next
 * sibling node and include it if it exists. Else it will just return the
 * one node.
 *
 * @returns [preview, truncated-start, truncated-end]
 */
export const postPreview = (
  body: SlateDoc,
  path: Path,
  maxChars: number = 200
): [SlateDoc, boolean, boolean] => {
  const editor = createEditor();
  editor.children = body;
  const block = Editor.above(editor, { at: path });
  if (!block) {
    return [[], false, false];
  }
  const blockPath = block[1];
  const node = block[0];
  const previewNodes: Node[] = [node];
  const nodeStr = Node.string(node);

  if (nodeStr.length < maxChars) {
    const nextPath = Path.next(blockPath);
    if (Node.has(editor, nextPath)) {
      const [nextNode] = Editor.node(editor, nextPath);
      if (nextNode) {
        const nextNodeStr = Node.string(nextNode);
        if (nodeStr.length + nextNodeStr.length <= maxChars) {
          previewNodes.push(nextNode);
        }
      }
    }
  }

  const previewDoc = wrapNodesAsDoc(previewNodes);

  // Hopefully means we've always taken the entire first block.
  const truncatedStart = !!blockPath.find((p) => p !== 0);
  const truncatedEnd = !isLastBlock(editor, blockPath);

  return [previewDoc, truncatedStart, truncatedEnd];
};

const findMentionsInText = (text: SlateDoc, postIds: PostId[]) => {
  return Array.from(Node.elements(text[0])).filter(
    (n) =>
      n[0]["type"] === ELEMENT_MENTION &&
      !!postIds.find((postId) => postId === n[0]["postId"])
  );
};

export const findMentionsInPosts = (
  posts: UserPost[],
  mentionedPostIds: PostId[]
): MentionContext[] => {
  const mentions: MentionContext[] = [];
  posts.forEach((post) => {
    const previews = new Set<string>();
    const mentionNodes = findMentionsInText(post.post.body, mentionedPostIds);
    console.debug(
      `findMentionsInPosts: got ${mentionNodes.length} mentionNodes`
    );
    mentionNodes.forEach((mentionNode) => {
      // The path returned by the search function is a little off because we
      // search from the first child.
      const path = [0, ...mentionNode[1]];
      const [preview, truncatedStart, truncatedEnd] = mentionPreview(
        post.post.body,
        path
      );
      // This isn't perfect (assumes same object always serialized to the same
      // thing), but probably ok for now.
      const previewId = JSON.stringify(preview);
      if (!previews.has(previewId)) {
        const mentionContext = {
          post: post,
          text: preview,
          path: path,
          truncatedStart: truncatedStart,
          truncatedEnd: truncatedEnd,
        };
        mentions.push(mentionContext);
        previews.add(previewId);
      }
    });
  });
  return mentions;
};

export const findMentionsInPost = (
  post: UserPost,
  mentionedPostId: PostId
): MentionContext[] => {
  return findMentionsInPosts([post], [mentionedPostId]);
};

export const getMentionIds = (slateDoc: SlateDoc): PostId[] => {
  return Array.from(Node.elements(slateDoc[0]))
    .filter((n) => n[0]["type"] === ELEMENT_MENTION)
    .map((n) => {
      const postId = n[0]["postId"];
      if (!postId || typeof postId !== "string") {
        throw new Error();
      }
      return postId;
    })
    .filter((postId) => !!postId);
};
