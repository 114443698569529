import firebase from "firebase";
import "firebase/analytics";

type FirebaseMode = "emulator" | "service";

type FirebaseAppConfig = {
  mode?: FirebaseMode;
};

export type FirebaseConfig = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  appId: string;
  databaseURL: string;
  storageBucket: string;
  locationId: string;
  messagingSenderId: string;
  measurementId: string;
  appOptions?: FirebaseAppConfig;
};

const toFirebaseMode = (modeStr: string | undefined): FirebaseMode => {
  switch (modeStr) {
    case "emulator":
      return modeStr;
    case "service":
      return modeStr;
    default:
      return "service";
  }
};

let app: firebase.app.App;

export class FirebaseApp {
  static fromEnv(): firebase.app.App {
    const config = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
      appId: process.env.REACT_APP_FIREBASE_APP_ID!,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL!,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
      locationId: process.env.REACT_APP_FIREBASE_LOCATION_ID!,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID!,
      appOptions: {
        mode: toFirebaseMode(process.env.REACT_APP_FIREBASE_MODE),
      },
    };
    return FirebaseApp.fromConfig(config);
  }

  static fromConfig(config: FirebaseConfig): firebase.app.App {
    if (!app) {
      console.debug("Init firebase");
      app = firebase.initializeApp(config);
      const auth = app.auth();
      if (config.appOptions?.mode === "emulator") {
        console.debug(`Running in emulator mode`);
        auth.useEmulator("http://localhost:8081");
      }
      app.analytics();
    }
    return app;
  }
}
