import { CircularProgress, Dialog, Grid } from "@material-ui/core";
import { useState } from "react";
import { Database } from "../../services/database/FirestoreDatabase";
import { ChannelId, UserRecord } from "../../services/database/Types";
import { OpenGraph } from "../../services/OpenGraph";
import {
  SearchDialog,
  AUTOCOMPLETE_SEARCH_BOX_KEY,
  AUTOCOMPLETE_SEARCH_BOX_KEYMODIFIER,
} from "./SearchDialog";

export const useSearchDialog = (
  user: UserRecord | undefined,
  channelId: ChannelId | undefined,
  database: Pick<Database, "createPost" | "getAllPostsByTitlePrefix">,
  opengraph: OpenGraph
) => {
  const [open, setOpen] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const onClose = () => setOpen(false);

  let dialogComponent;
  if (!user || !channelId) {
    dialogComponent = <></>;
  } else if (showProgress) {
    dialogComponent = (
      <Grid
        container
        spacing={0}
        direction={"column"}
        alignItems={"center"}
        justify={"center"}
        style={{ minHeight: "100px" }}
      >
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  } else {
    dialogComponent = (
      <SearchDialog
        user={user}
        channelId={channelId}
        setShowProgress={setShowProgress}
        onClose={onClose}
        opengraph={opengraph}
        database={database}
      />
    );
  }

  const dialog = (
    <Dialog
      maxWidth={"sm"}
      fullWidth={true}
      onClose={onClose}
      onExited={() => setShowProgress(false)}
      open={open}
      aria-labelledby="search-box-dialog-title"
      aria-describedby="search-box-dialog-description"
    >
      {dialogComponent}
    </Dialog>
  );

  return {
    dialogOpen: open,
    setDialogOpen: setOpen,
    dialog: dialog,
  };
};

export const AUTOCOMPLETE_SEARCH_BOX_HOTKEY = `${AUTOCOMPLETE_SEARCH_BOX_KEYMODIFIER}+${AUTOCOMPLETE_SEARCH_BOX_KEY}`;
export const AUTOCOMPLETE_SEARCH_BOX_ESCKEY = "escape";
