import firebase from "firebase";
import firebaseui from "firebaseui";
import { useEffect, useState } from "react";
import { StyledFirebaseAuth } from "react-firebaseui";
import { Redirect } from "react-router-dom";
import useSWR from "swr";
import { Database } from "../services/database/FirestoreDatabase";
import { UserRecord } from "../services/database/Types";
import { Paths } from "../services/Paths";
import { SwrPaths } from "../services/SwrPaths";
import { DelayLoadWrapper } from "./DelayLoadWrapper";

export const SignInView = ({
  firebaseAuth,
  signInTitleOverride,
  loggedInUser,
  database,
}: {
  firebaseAuth: firebase.auth.Auth;
  signInTitleOverride?: string;
  loggedInUser: UserRecord | undefined;
  database: Pick<Database, "getWhitelisted">;
}) => {
  const [authUser, setAuthUser] = useState<firebase.User>();

  const whitelistedResult = useSWR(
    () => {
      if (!authUser || !authUser.email) {
        return null;
      }
      return [SwrPaths.whitelist(authUser.email), authUser.email];
    },
    (path, email) => database.getWhitelisted(email)
  );

  useEffect(() => {
    if (signInTitleOverride) {
      const el = document.querySelector(".firebaseui-title");
      if (el && el.textContent === "Sign in with email") {
        el.textContent = signInTitleOverride;
      }
    }
  });

  if (!authUser) {
    const config: firebaseui.auth.Config = {
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: true,
        },
      ],
      signInFlow: "redirect",
      callbacks: {
        signInSuccessWithAuthResult: (
          authResult: firebase.auth.UserCredential,
          redirectUrl: string | undefined
        ) => {
          if (!authResult.user) {
            throw new Error(
              "Authed user should not be null after successful signin"
            );
          }

          console.debug(
            `Successful signin, got user ${authResult.user.uid} : ${authResult.user.displayName}`
          );

          setAuthUser(authResult.user);

          return false;
        },
      },
    };

    return <StyledFirebaseAuth uiConfig={config} firebaseAuth={firebaseAuth} />;
  } else {
    const ready =
      whitelistedResult.data &&
      ((whitelistedResult.data.result && loggedInUser) ||
        !whitelistedResult.data.result);

    return (
      <DelayLoadWrapper
        // Whats going on here is we're waiting for the user to be logged
        // in at the app auth level before redirecting to avoid a timing
        // issue in Authd route.
        ready={!!ready}
        error={!!whitelistedResult.error}
        label="signin"
        render={() => {
          if (whitelistedResult.data!.result) {
            return <Redirect to={Paths.channel()} />;
          } else {
            firebaseAuth.signOut();
            return <Redirect to={Paths.waitlisted()} />;
          }
        }}
      />
    );
  }
};
