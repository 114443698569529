import {
  AppBar,
  Container,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  MuiThemeProvider,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { PropsWithChildren, Fragment, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { HashLink } from "react-router-hash-link";
import useSWR from "swr";
import { Database } from "../services/database/FirestoreDatabase";
import {
  ChannelId,
  ChannelRecord,
  UserRecord,
} from "../services/database/Types";
import { OpenGraph } from "../services/OpenGraph";
import { SwrPaths } from "../services/SwrPaths";
import { themes } from "../styles/Theme";
import {
  AUTOCOMPLETE_SEARCH_BOX_ESCKEY,
  AUTOCOMPLETE_SEARCH_BOX_HOTKEY,
  useSearchDialog,
} from "./search/useSearchDialog";
import { Paths } from "../services/Paths";
import { DelayLoadWrapper } from "./DelayLoadWrapper";

export const ViewContainer = ({
  loggedInUser,
  breadcrumbs,
  database,
  opengraph,
  children,
  channelId,
}: PropsWithChildren<ViewContainerProps>) => {
  const channelResult = useSWR(
    () => {
      return [SwrPaths.channel(channelId), channelId];
    },
    async (path, channelId) => database.getChannel(channelId)
  );

  const searchDialog = useSearchDialog(
    loggedInUser,
    channelId,
    database,
    opengraph
  );
  const classes = useStyles();
  const [drawerOpen, toggleDrawer] = useSideDrawerState();

  const loggedIn = !!loggedInUser;

  useHotkeys(AUTOCOMPLETE_SEARCH_BOX_HOTKEY, (event) => {
    event.preventDefault();
    event.stopPropagation();
    searchDialog.setDialogOpen((v: boolean) => !v);
  });

  useHotkeys(AUTOCOMPLETE_SEARCH_BOX_ESCKEY, () => {
    searchDialog.setDialogOpen(false);
  });

  return (
    <DelayLoadWrapper
      ready={!!channelResult.data}
      error={channelResult.error}
      render={() => (
        <MuiThemeProvider theme={themes[channelResult.data!.theme]}>
          <CssBaseline />

          {loggedIn && searchDialog.dialog}

          <AppBar elevation={0} position="fixed" color="transparent">
            <Toolbar variant="dense" className={classes.toolbar}>
              {loggedIn && (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  size="small"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Breadcrumbs breadcrumbs={breadcrumbs} loggedIn={loggedIn} />
            </Toolbar>
          </AppBar>

          {loggedInUser && (
            <SideDrawer
              loggedInUser={loggedInUser}
              drawerOpen={drawerOpen}
              database={database}
              toggleDrawer={toggleDrawer}
            />
          )}

          <Container maxWidth="sm" className={classes.viewContainer}>
            <div className={classes.offset} />
            <>{children}</>
          </Container>
        </MuiThemeProvider>
      )}
    />
  );
};

const Breadcrumbs = ({
  breadcrumbs,
  loggedIn,
}: {
  breadcrumbs: Breadcrumb[];
  loggedIn: boolean;
}) => {
  const classes = useStyles();
  const comps = breadcrumbs.map((bc, index) => {
    return (
      <Fragment key={index}>
        <Tooltip title={bc.text}>
          <Typography variant="body2" className={classes.breadcrumbContainer}>
            {!loggedIn && bc.type === "channel" ? (
              <span className={classes.inactiveBreadcrumb}>{bc.text}</span>
            ) : (
              <HashLink smooth className={classes.breadcrumb} to={bc.href}>
                {bc.text}
              </HashLink>
            )}
          </Typography>
        </Tooltip>
        {index < breadcrumbs.length - 1 && "/"}
      </Fragment>
    );
  });
  return <>{comps}</>;
};

export type ViewContainerProps = {
  loggedInUser: UserRecord | undefined;
  channelId: ChannelId;
  breadcrumbs: Breadcrumb[];
  database: Pick<
    Database,
    "createPost" | "getAllPostsByTitlePrefix" | "getUserChannels" | "getChannel"
  >;
  opengraph: OpenGraph;
};

export type BreadcrumbType = "channel" | "post";

export type Breadcrumb = {
  text: string;
  href: string;
  type: BreadcrumbType;
};

const useSideDrawerState = (): [
  boolean,
  (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void
] => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawerOpen(open);
    };

  return [drawerOpen, toggleDrawer];
};

const SideDrawer = ({
  loggedInUser,
  drawerOpen,
  database,
  toggleDrawer,
}: {
  loggedInUser: UserRecord;
  drawerOpen: boolean;
  database: Pick<Database, "getUserChannels">;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}) => {
  const classes = useStyles();
  const history = useHistory();
  const channelsResult = useSWR(
    SwrPaths.userChannels(loggedInUser.uid),
    async () => database.getUserChannels(loggedInUser.uid)
  );
  const channelRecords = channelsResult.data || [];

  if (channelsResult.error) {
    console.error(`Error fetching channels; error=${channelsResult.error}`);
  }

  const handleChannelChange = (channelRecord: ChannelRecord) => {
    history.push(Paths.channel(channelRecord.id));
  };

  return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      BackdropProps={{ invisible: true }}
      elevation={8}
      className={classes.drawer}
    >
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List dense subheader={<ListSubheader>Channels</ListSubheader>}>
          {channelRecords.map((channelRecord: ChannelRecord) => (
            <ListItem
              button
              key={channelRecord.name}
              onClick={() => handleChannelChange(channelRecord)}
            >
              <ListItemText primary={<b>#{channelRecord.name}</b>} />
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  breadcrumbContainer: {
    maxWidth: "150px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  breadcrumb: {
    borderRadius: "4px",
    color: theme.palette.common.black,
    textDecoration: "none",
    padding: "4px 4px",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  inactiveBreadcrumb: {
    borderRadius: "4px",
    color: theme.palette.common.black,
    textDecoration: "none",
    padding: "4px 4px",
  },
  toolbar: {
    minHeight: "32px",
    paddingLeft: "8px",
    backgroundColor: "white",
  },
  offset: {
    height: "32px",
    minHeight: "32px",
  },
  viewContainer: {
    marginTop: "-5px",
  },
  list: {
    width: 200,
    overflow: "auto",
  },
  drawer: {
    flexShrink: 0,
  },
}));
