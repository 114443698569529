import { Divider, makeStyles, TextField } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import Autosuggest, {
  ChangeEvent,
  OnSuggestionSelected,
  SuggestionsFetchRequested,
} from "react-autosuggest";
import { useHistory } from "react-router-dom";
import { Database } from "../../services/database/FirestoreDatabase";
import { ChannelId, UserRecord, idgen } from "../../services/database/Types";
import { OpenGraph } from "../../services/OpenGraph";
import { Paths } from "../../services/Paths";
import * as SearchHelpers from "../../services/search/SearchHelpers";
import { SearchSuggestion } from "../../services/search/Types";
import { EMPTY_SLATE_DOC, linkToSlateDoc } from "../editor/Utils";
import { CreateNewAnonPostSearchResult } from "./CreateNewAnonPostSearchResult";
import { CreateNewPostSearchResult } from "./CreateNewPostSearchResult";
import { NavigateToPostSearchResult } from "./NavigateToPostSearchResult";
import { useAutocompleteState } from "./useAutocompleteState";

export const SearchDialog = ({
  user,
  channelId,
  onClose,
  setShowProgress,
  opengraph,
  database,
}: {
  user: UserRecord;
  channelId: ChannelId;
  onClose: () => void;
  setShowProgress: Dispatch<SetStateAction<boolean>>;
  opengraph: OpenGraph;
  database: Pick<Database, "createPost" | "getAllPostsByTitlePrefix">;
}) => {
  const classes = useStyles();
  const [value, setValue] = useState<string>("");
  const getSuggestions = useCallback(
    (titlePrefix: string) =>
      SearchHelpers.getSuggestions(user.uid, channelId, titlePrefix, database),
    [channelId, user, database]
  );
  const [suggestions, startSuggestionsRequest] = useAutocompleteState(
    getSuggestions,
    opengraph
  );

  const onChange: AutocompleteSearchBoxOnChangeFn = (event, changeEvent) => {
    setValue(changeEvent.newValue);
  };
  const onSuggestionsFetchRequested: SuggestionsFetchRequested = async (
    request
  ) => {
    const value = request.value;
    startSuggestionsRequest(value);
  };

  const onSuggestionsClearRequested = () => {
    startSuggestionsRequest("");
  };

  const getSuggestionValue = (suggestion: SearchSuggestion) => suggestion.title;
  const renderSuggestion = (suggestion: SearchSuggestion) => {
    switch (suggestion.action) {
      case "createNewAnonPost":
        return <CreateNewAnonPostSearchResult />;
      case "createNewPost":
      case "createNewPostFromResolvedLink":
        return <CreateNewPostSearchResult title={suggestion.title} />;
      case "navigateToPost":
        return (
          <NavigateToPostSearchResult user={user} suggestion={suggestion} />
        );
      default:
        throw new Error(
          `SearchSuggestion is malformed; suggestion=${suggestion}`
        );
    }
  };

  const history = useHistory();

  const onSuggestionSelected: OnSuggestionSelected<SearchSuggestion> = async (
    event,
    data
  ) => {
    switch (data.suggestion.action) {
      case "createNewAnonPost":
      case "createNewPost":
      case "createNewPostFromResolvedLink":
        setShowProgress(true);

        const newBody =
          data.suggestion.action === "createNewPostFromResolvedLink"
            ? linkToSlateDoc(data.suggestion.link)
            : EMPTY_SLATE_DOC;

        const newTitle =
          data.suggestion.action === "createNewAnonPost"
            ? "IGNORE"
            : data.suggestion.title;

        const postId = idgen();
        await database.createPost(
          channelId,
          user.uid,
          postId,
          newBody,
          newTitle
        );

        console.debug(
          `new post created with title ${newTitle} and post id ${postId}`
        );

        history.push(Paths.post(channelId, postId)); // do redirect to permanent note url
        onClose();
        return;
      case "navigateToPost":
        history.push(
          Paths.post(data.suggestion.channelId, data.suggestion.postId)
        );
        onClose();
        break;
      default:
        throw new Error(`Search action is malformed; data=${data}`);
    }
  };

  const renderInputComponent = (inputProps: any) => {
    return (
      <React.Fragment>
        <TextField
          color="primary"
          variant="outlined"
          margin="none"
          fullWidth
          autoFocus
          rowsMax={1}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
            ...inputProps,
          }}
        />
        <Divider />
      </React.Fragment>
    );
  };

  const inputProps = {
    onKeyDown: (event: React.KeyboardEvent) => {
      if (isAutocompleteSearchBoxHotkey(event)) {
        event.preventDefault();
        onClose();
      }
    },
    placeholder: AUTOCOMPLETE_SEARCH_BOX_PROMPT,
    value: value,
    onChange: onChange,
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      alwaysRenderSuggestions={true}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={inputProps}
      renderInputComponent={renderInputComponent}
      theme={{
        container: classes.container,
        suggestionsContainer: classes.suggestionsContainer,
        suggestionsList: classes.suggestionsList,
        suggestionHighlighted: classes.suggestionHighlighted,
      }}
    />
  );
};

export const AUTOCOMPLETE_SEARCH_BOX_KEY = "p";
export const AUTOCOMPLETE_SEARCH_BOX_KEYMODIFIER = "command";
export const AUTOCOMPLETE_SEARCH_BOX_PROMPT = "Type to create or open";

const isAutocompleteSearchBoxHotkey = (event: React.KeyboardEvent) => {
  if (AUTOCOMPLETE_SEARCH_BOX_KEYMODIFIER !== "command") {
    throw new Error(
      "Search box key modifier should be set to command, or logic needs to be updated"
    );
  }

  return event.metaKey && event.key === AUTOCOMPLETE_SEARCH_BOX_KEY;
};

type AutocompleteSearchBoxOnChangeFn = (
  event: React.FormEvent<any>,
  newValue: ChangeEvent
) => void;

const useStyles = makeStyles((theme) => ({
  suggestionsContainer: {},
  container: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(0),
  },
  suggestionsList: {
    listStyleType: "none",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  suggestionHighlighted: {
    backgroundColor: theme.palette.action.selected,
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
      borderWidth: "0px",
    },
    borderBottom: "1px",
  },
  cssFocused: {},
  notchedOutline: {
    borderWidth: "0px",
  },
}));
