import { UserId, PostId, ChannelId } from "./database/Types";

export class Paths {
  static channel(channelId?: ChannelId) {
    if (channelId) {
      return `/channel/${channelId}`;
    } else {
      return `/channel`;
    }
  }
  static post(userId: UserId, postId: PostId) {
    return `/post/${userId}/${postId}`;
  }
  static postPreview(userId: UserId, postId: PostId) {
    return `/post-preview/${userId}/${postId}`;
  }
  static postReply(channelId: ChannelId, postId: PostId, threadPostId: PostId) {
    return `/post/${channelId}/${postId}#${Paths.threadPostId(threadPostId)}`;
  }
  static signup() {
    return "/signup";
  }
  static login() {
    return "/login";
  }
  static logout() {
    return "/logout";
  }
  static waitlist() {
    return "/waitlist";
  }
  static waitlisted() {
    return "/waitlisted";
  }
  static profile(userId?: UserId) {
    if (userId) {
      return `/profile/${userId}`;
    } else {
      return "/profile";
    }
  }
  static landingPage() {
    return "/join";
  }
  static root() {
    return "/";
  }

  /**
   * Helpers
   */

  static threadPostId(threadPostId: PostId) {
    return `thread_post_${threadPostId.replace(/-/g, "")}`;
  }
}
