import {
  Divider,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import useSWR from "swr";
import { Database } from "../services/database/FirestoreDatabase";
import { ChannelId } from "../services/database/Types";
import { SwrPaths } from "../services/SwrPaths";
import { useGlobalStyles } from "../styles/GlobalStyles";
import { DelayLoadWrapper } from "./DelayLoadWrapper";
import { ProfilePostCard } from "./ProfilePostCard";
import { useBreadcrumbs } from "./useBreadcrumbs";
import { Breadcrumb } from "./ViewContainer";
import _ from "lodash";

export const ChannelView = ({
  channelId,
  database,
  onBreadcrumbsChange,
}: {
  channelId: ChannelId;
  database: Pick<Database, "getChannelPosts" | "getChannel">;
  onBreadcrumbsChange: (breadcrumbs: Breadcrumb[]) => void;
}) => {
  const classes = useStyles();

  const [pageInfos, setPageInfo] = useState<PageInfo[]>([
    { pageId: 0, lastUpdatedAt: undefined },
  ]);
  const channelResult = useSWR(
    () => {
      if (!channelId) {
        throw new Error("channelId not ready");
      }
      return [SwrPaths.channel(channelId), channelId];
    },
    async (path, channelId) => database.getChannel(channelId)
  );

  const handleScroll = (event: any) => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 200
    ) {
      setPageInfo((prevPageInfo: PageInfo[]) => {
        if (_.last(prevPageInfo)?.lastUpdatedAt) {
          const newPageInfo = Array.from(prevPageInfo);
          newPageInfo.push({
            pageId: newPageInfo.length,
            lastUpdatedAt: undefined,
          });
          return newPageInfo;
        } else {
          return prevPageInfo;
        }
      });
    }
  };

  const handlePageLoad = useCallback((pageId: number, lastUpdatedAt: Date) => {
    setPageInfo((prevPageInfo: PageInfo[]) => {
      if (!prevPageInfo[pageId].lastUpdatedAt) {
        const newPageInfo = Array.from(prevPageInfo);
        newPageInfo[pageId].lastUpdatedAt = lastUpdatedAt;
        return newPageInfo;
      } else {
        return prevPageInfo; //No-op
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useBreadcrumbs(channelResult.data, undefined, undefined, onBreadcrumbsChange);

  return (
    <DelayLoadWrapper
      ready={!!channelResult.data}
      error={channelResult.error}
      label="channel"
      render={() => {
        const pageTitle = `#${channelResult.data!.name} | Reweave`;
        const pageDescription = `The latest posts in #${
          channelResult.data!.name
        }`;

        const pages = pageInfos.map((pageInfo) => (
          <Page
            channelId={channelId}
            key={`page_${pageInfo.pageId}`}
            pageId={pageInfo.pageId}
            database={database}
            pageInfos={pageInfos}
            onPageLoad={handlePageLoad}
          />
        ));

        return (
          <>
            <Helmet>
              <title>{pageTitle}</title>
              <meta property="og:title" content={pageTitle} />
              <meta name="twitter:card" content="summary"></meta>
              <meta name="description" content={pageDescription}></meta>
            </Helmet>
            <Typography className={classes.title} variant="h1">
              #{channelResult.data!.name}
            </Typography>
            <Divider className={classes.profileDivider} />
            <List className={classes.root}>{pages}</List>
          </>
        );
      }}
    />
  );
};

type PageInfo = {
  pageId: number;
  lastUpdatedAt: Date | undefined;
};

const Page = ({
  channelId,
  pageId,
  pageInfos,
  database,
  onPageLoad,
}: {
  channelId: ChannelId;
  pageId: number;
  pageInfos: PageInfo[];
  database: Pick<Database, "getChannelPosts">;
  onPageLoad: (id: number, lastUpdatedAt: Date) => void;
}) => {
  const globalClasses = useGlobalStyles();

  const updatedAfter =
    pageId === 0 ? new Date() : pageInfos[pageId - 1].lastUpdatedAt;

  const postsResult = useSWR(
    () => {
      if (!updatedAfter) {
        throw new Error(`Previous page not ready; pageId=${pageId}`);
      }
      return [SwrPaths.channelPosts(channelId, pageId), channelId];
    },
    async (path, channelId) => database.getChannelPosts(channelId, updatedAfter)
  );

  if (postsResult.data && postsResult.data.length > 0) {
    onPageLoad(pageId, _.last(postsResult.data)!.post.updatedAt!);
  }

  const postRecords = postsResult.data || [];

  const listItems = postRecords.map((post) => {
    return (
      <ListItem
        alignItems="flex-start"
        key={post.post.id}
        className={globalClasses.cardListItem}
        disableGutters
      >
        <ProfilePostCard post={post.post} />
      </ListItem>
    );
  });
  return <>{listItems}</>;
};

export type ChannelViewParams = {
  channelId: ChannelId;
};

const useStyles = makeStyles((theme) => ({
  root: {},
  inline: {
    display: "inline",
  },
  profileDivider: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  title: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));
