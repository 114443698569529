import firebase from "firebase";
import { UserRecord } from "./Types";

export const UserRecordConverter = {
  toFirestore: (ur: UserRecord): firebase.firestore.DocumentData => {
    return ur;
  },
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): UserRecord => {
    const data = snapshot.data(options)!;
    return {
      uid: data.uid,
      displayName: data.displayName,
      username: data.username,
      description: data.description,
      mainChannelId: data.mainChannelId,
    };
  },
};
