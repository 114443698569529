import { AppContainer } from "./components/AppContainer";
import { DelayLoadWrapper } from "./components/DelayLoadWrapper";
import { useAppAuth } from "./components/useAppAuth";
import "./FirebaseUI.global.css";
import { FirestoreDatabase } from "./services/database/FirestoreDatabase";
import { FirebaseApp } from "./services/FirebaseApp";
import { OpenGraphImpl } from "./services/OpenGraph";

const app = FirebaseApp.fromEnv();
const database = FirestoreDatabase.fromApp(app);
const auth = app.auth();
const opengraph = OpenGraphImpl.fromId("3b46c618-18ef-4de0-b522-9a72138b9357");

export default function App() {
  const [authReady, userRecord] = useAppAuth(auth, database);
  return (
    <DelayLoadWrapper
      color={"inherit"}
      ready={authReady}
      error={false}
      label="App"
      render={() => {
        return (
          <AppContainer
            loggedInUser={userRecord}
            firebaseAuth={auth}
            database={database}
            opengraph={opengraph}
          />
        );
      }}
    />
  );
}
