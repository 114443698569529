import { MentionNodeData } from "@blfrg.xyz/slate-plugins";
import { useCallback, useState } from "react";
import { Database } from "../../services/database/FirestoreDatabase";
import {
  PostId,
  UserPost,
  UserRecord,
  ChannelId,
  idgen,
} from "../../services/database/Types";
import { EMPTY_SLATE_DOC } from "./Utils";

export const useMentions = (
  database: Pick<Database, "createPost" | "getAllPostsByTitlePrefix">,
  author: UserRecord,
  channelId: ChannelId
): [
  MentionNodeData[],
  (newSearch: string) => void,
  (option: MentionNodeData) => void
] => {
  const getMentionables = useCallback(
    async (prefixTitle: string): Promise<MentionNodeData[]> => {
      const posts = await database.getAllPostsByTitlePrefix(
        channelId,
        prefixTitle
      );
      return posts.map((post: UserPost) => {
        return {
          value: post.post.title,
          postId: post.post.id,
          authorId: post.post.authorId,
          authorUsername: post.user.username,
          channelId: post.post.channelId,
          exists: true,
        };
      });
    },
    [channelId, database]
  );

  const [mentionables, setMentionables] = useState<MentionNodeData[]>([]);
  const [search, setSearch] = useState<string>();

  const onMentionSearchChanged = useCallback(
    (newSearch: string) => {
      const updateMentionables = async () => {
        if (search !== newSearch) {
          const newMentionables = await getMentionables(newSearch);
          // Only insert the search query if it doesnt exist exactly in the results.
          const ownPostExists =
            newMentionables.find((m) => {
              return m.value === newSearch && m.authorId === author.uid;
            }) === undefined;
          if (ownPostExists) {
            const newMention: MentionNodeData = {
              value: newSearch,
              authorId: author.uid,
              channelId: channelId,
              authorUsername: author.username,
              postId: idgen(),
              exists: false,
            };
            if (newSearch) {
              newMentionables.splice(0, 0, newMention);
            }
          }

          setMentionables(newMentionables);
          setSearch(newSearch);
        }
      };
      updateMentionables();
    },
    [author.uid, author.username, getMentionables, search, channelId]
  );

  const onMentionAdded = useCallback(
    (mention: MentionNodeData) => {
      const addMentionToDatabase = async () => {
        if ("exists" in mention && mention["exists"] === false) {
          console.debug(`adding mention ${mention.value}`);

          const postId: PostId = mention.postId;
          const postTitle: string = mention.value;

          await database.createPost(
            channelId,
            author.uid,
            postId,
            EMPTY_SLATE_DOC,
            postTitle
          );
        } else {
          console.debug(`not adding mention ${mention.value}; already exists`);
        }
      };
      addMentionToDatabase();
    },
    [database, author, channelId]
  );

  return [mentionables, onMentionSearchChanged, onMentionAdded];
};
