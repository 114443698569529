import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Box,
  Typography,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { UserRecord, PostRecord } from "../services/database/Types";
import { useGlobalStyles } from "../styles/GlobalStyles";
import { DateTime } from "luxon";
import { useMenuState } from "./useMenuState";
import { Database } from "../services/database/FirestoreDatabase";
import { useCallback } from "react";
import { Paths } from "../services/Paths";

export const PostHeader = ({
  userRecord,
  postRecord,
  loggedInUser,
  database,
}: {
  userRecord: UserRecord;
  postRecord: PostRecord;
  loggedInUser: UserRecord | undefined;
  database: Pick<Database, "deletePost">;
}) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { menuAnchorEl, onMenuClick, onMenuClose } = useMenuState();
  const history = useHistory();
  const updatedAt = DateTime.fromJSDate(postRecord.updatedAt || new Date());
  const loggedIn = !!loggedInUser;

  const handleDelete = useCallback(
    async (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      await database.deletePost(
        postRecord.channelId,
        postRecord.id!,
        postRecord.threadPosts
      );
      onMenuClose();
      console.debug(`Post ${postRecord.id} deleted`);
      history.push(Paths.channel());
    },
    [database, postRecord, onMenuClose, history]
  );

  return (
    <Box className={classes.title}>
      {!postRecord.parentId && postRecord.title !== "IGNORE" && (
        <Typography variant="h1">{postRecord.title}</Typography>
      )}
      <Typography variant="subtitle1">
        <span>
          {loggedIn ? (
            <span className={globalClasses.link}>{userRecord.displayName}</span>
          ) : (
            <Link
              className={globalClasses.link}
              to={Paths.profile(userRecord.uid)}
            >
              {userRecord.displayName}
            </Link>
          )}
        </span>
        <span className={classes.subtitlePart}>
          <Link
            className={classes.subitleLink}
            to={Paths.post(postRecord.channelId, postRecord.id!)}
          >
            {updatedAt.toFormat("MMM d")}
          </Link>
        </span>
        <span className={classes.subtitlePart}>
          {loggedIn && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              size="small"
              onClick={onMenuClick}
            >
              <MoreHorizIcon fontSize="inherit" />
              <Menu
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={onMenuClose}
                onBlur={onMenuClose}
              >
                <MenuItem
                  dense
                  onClick={handleDelete}
                  data-testid={`delete-${postRecord.id}`}
                >
                  <DeleteIcon fontSize="small" />
                  <span className={classes.subtitleMoreMenuItemText}>
                    Delete
                  </span>
                </MenuItem>
              </Menu>
            </IconButton>
          )}
        </span>
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  subitleLink: {
    textDecoration: "none",
    color: "inherit",
  },
  title: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  subtitlePart: {
    paddingLeft: theme.spacing(1),
  },
  subtitleMoreMenuItemText: {
    paddingLeft: theme.spacing(1),
  },
}));
