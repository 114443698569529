import { useRef, useEffect, useCallback } from "react";

/**
 * Note: ms may not be enough precision - there may be a small window where we
 * could lose updates.
 */
export const useAutoSave = <T extends {}>(
  saveFn: (state: T) => Promise<void>,
  dateFn: () => number = Date.now
): [(record: T) => void, () => boolean] => {
  const latestUpdateTimeMs = useRef(dateFn());
  const latestUpdateState = useRef<T>();
  const remoteUpdateTimeMs = useRef(dateFn());
  const requestPending = useRef(false);

  const onChange = (record: T) => {
    latestUpdateTimeMs.current = dateFn();
    latestUpdateState.current = record;
  };

  const isDirty = useCallback(() => {
    return latestUpdateTimeMs.current > remoteUpdateTimeMs.current;
  }, []);

  useEffect(() => {
    console.debug(`useAutoSave: registering; isDirty=${isDirty()}`);
    const intervalId = setInterval(async () => {
      if (
        latestUpdateTimeMs.current > remoteUpdateTimeMs.current &&
        !requestPending.current &&
        latestUpdateState.current
      ) {
        requestPending.current = true;
        const updateTimeMs = latestUpdateTimeMs.current;
        try {
          await saveFn(latestUpdateState.current);
          remoteUpdateTimeMs.current = updateTimeMs;
        } catch (e) {
          console.warn(`useAutoSave: update failed; e=${e}`);
        } finally {
          requestPending.current = false;
        }
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
      console.debug(`useAutoSave: deregistering; isDirty=${isDirty()}`);
    };
  }, [saveFn, isDirty]);

  return [onChange, isDirty];
};
