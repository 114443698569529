import { Box, makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { Database } from "../services/database/FirestoreDatabase";
import { MentionNodeData } from "@blfrg.xyz/slate-plugins";
import { ChannelRecord, PostId, PostRecord, UserRecord } from "../services/database/Types";
import { Editor } from "./editor/Editor";
import { SlateDoc } from "./editor/Types";
import { useMentions } from "./editor/useMentions";
import { EMPTY_SLATE_DOC } from "./editor/Utils";
import { IsDirtyFn } from "./PostView";
import { useAutoSave } from "./useAutoSave";
import { MentionSuggestionLine } from "./editor/MentionSuggestionLine";

export const PostBody = ({
  database,
  registerState,
  postRecord,
  userRecord,
  loggedInUser,
  channel,
}: {
  postRecord: PostRecord;
  userRecord: UserRecord;
  loggedInUser: UserRecord | undefined;
  channel: ChannelRecord;
  database: Pick<
    Database,
    "updatePost" | "createPost" | "getAllPostsByTitlePrefix"
  >;
  registerState: (postId: PostId, registeredFn: IsDirtyFn) => () => void;
}) => {
  const readOnly = !(loggedInUser && loggedInUser.uid === postRecord.authorId);
  const postId = postRecord.id!;
  const classes = useStyles();
  const prevPostId = useRef<PostId | undefined>();
  const save = useCallback(
    async (doc: SlateDoc) => {
      return database.updatePost(channel.id, postId, {
        body: doc,
        parentId: postRecord.parentId || null,
      });
    },
    [channel, postId, database, postRecord]
  );
  const [onChange, isDirty] = useAutoSave(save);
  const [mentionables, onMentionSearchChanged, onMentionAdded] = useMentions(
    database,
    userRecord,
    channel.id,
  );

  // Body has to be re-set via an effect because postRecord may in fact change if
  // we navigate to a new post for example.
  const [body, setBody] = useState<SlateDoc>(EMPTY_SLATE_DOC);
  useEffect(() => {
    if (
      prevPostId.current === undefined ||
      prevPostId.current !== postRecord.id
    ) {
      console.debug(`Set body from remote; postId=${postRecord.id}`);
      setBody(postRecord.body);
    }
  }, [postRecord]);

  // Tell the parent component if we have dirty state.
  useEffect(() => {
    const deregisterState = registerState(postId, isDirty);
    return () => {
      deregisterState();
    };
  }, [postId, registerState, isDirty]);

  // This has to come last.
  useEffect(() => {
    prevPostId.current = postId;
  });

  const mentionableElementFn = (option: MentionNodeData): JSX.Element => {
    return <MentionSuggestionLine uid={userRecord.uid} option={option} />;
  };

  return (
    <Box className={readOnly ? classes.readOnlyCard : classes.editableCard}>
      <Editor
        body={body}
        readOnly={readOnly}
        onChange={(value: SlateDoc) => {
          setBody(value); // Immediate local update
          onChange(value); // Delayed remote update
        }}
        mentionTypeaheadComponents={{
          mentionables,
          onMentionSearchChanged,
          onMentionAdded,
          mentionableElementFn,
        }}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  readOnlyCard: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
  },
  editableCard: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
    "&:hover:not(:focus)": {
      borderRadius: "4px",
      borderColor: theme.palette.action.selected,
      backgroundColor: theme.palette.action.selected,
    },
  },
}));
