import { useEffect, useState } from "react";
import { OpenGraph } from "../../services/OpenGraph";
import {
  CreateNewPostFromResolvedLinkSuggestion,
  SearchSuggestion,
} from "../../services/search/Types";

export const useAutocompleteState = (
  getSuggestions: (titlePrefix: string) => Promise<SearchSuggestion[]>,
  opengraph: OpenGraph
): [SearchSuggestion[], (value: string) => void] => {
  const [suggestions, setSuggestions] = useState<SearchSuggestionState>({
    perm: [
      {
        title: "",
        action: "createNewAnonPost",
      },
    ],
    link: [],
    text: [],
  });
  const suggestionsList = [
    ...suggestions.perm,
    ...suggestions.link,
    ...suggestions.text,
  ];
  const [value, setValue] = useState<string>();

  useEffect(() => {
    console.debug(`useAutocompleteState: register`);
    let isSubscribed = true;

    const startDatabaseRequest = async (value: string) => {
      console.debug(`Issue db suggestions request`);
      try {
        const suggestions = await getSuggestions(value);
        if (!isSubscribed) {
          console.debug(`Got stale db response, ignoring`);
        } else {
          console.debug(
            `Request has completed, setting suggestions; count=${suggestions.length}`
          );
          setSuggestions((prevState) => {
            return Object.assign({}, prevState, { text: suggestions });
          });
        }
      } catch (e) {
        console.debug(`Db request failed, not adding suggestions; error=${e}`);
      }
    };

    const startOpenGraphRequest = async (value: string) => {
      console.debug(`Issue fetch title from og request`);
      const title = await opengraph.getTitle(value);

      if (!isSubscribed) {
        console.debug(`Got stale og response, ignoring`);
      } else if (title) {
        console.debug(
          `Og request complete, updating suggestions; title=${title}`
        );
        const suggestion: CreateNewPostFromResolvedLinkSuggestion = {
          title: title,
          link: value,
          action: "createNewPostFromResolvedLink",
        };
        setSuggestions((prevState) => {
          return Object.assign({}, prevState, { link: [suggestion] });
        });
      } else {
        console.debug(`Og request failed, not adding suggestions`);
      }
    };

    const startRequests = (value: string) => {
      if (value === "") {
        return;
      }

      startDatabaseRequest(value);

      if (value.startsWith("http") || value.startsWith("https")) {
        startOpenGraphRequest(value);
      }
    };

    if (value) {
      startRequests(value);
    }

    return () => {
      console.debug(`useAutocompleteState: unregister`);
      isSubscribed = false;
    };
  }, [value, opengraph, getSuggestions]);

  const startSuggestionsRequest = (newValue: string | undefined) => {
    console.debug(`Kick off suggestions requests for ${newValue}`);
    setValue(newValue);
  };

  return [suggestionsList, startSuggestionsRequest];
};

type SearchSuggestionState = {
  perm: SearchSuggestion[];
  link: SearchSuggestion[];
  text: SearchSuggestion[];
};
