import firebase from "firebase";
import { useState, useEffect } from "react";
import { Database } from "../services/database/FirestoreDatabase";
import { UserRecord } from "../services/database/Types";

export const useAppAuth = (
  auth: firebase.auth.Auth,
  database: Pick<Database, "getOrInitializeUser" | "getWhitelisted">
): [boolean, UserRecord | undefined] => {
  const [userRecord, setUserRecord] = useState<UserRecord>();
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    console.debug(`useAppAuth: Subscribing...`);
    const unsubscribe = auth.onAuthStateChanged(
      async (firebaseUser: firebase.User | null) => {
        if (firebaseUser) {
          if (
            !firebaseUser.uid ||
            !firebaseUser.displayName ||
            !firebaseUser.email
          ) {
            console.error(
              `Firebase user is malformed; user=${JSON.stringify(firebaseUser)}`
            );
            return;
          }
          try {
            const whitelisted = await database.getWhitelisted(
              firebaseUser.email
            );

            if (!whitelisted.result) {
              console.debug(`useAppAuth: not whitelisted, signing out`);
              auth.signOut();
              setReady(true);
              return;
            }

            const userRecord = await database.getOrInitializeUser(
              firebaseUser.uid,
              firebaseUser.displayName,
              firebaseUser.email
            );

            setUserRecord(userRecord);
            setReady(true);
          } catch (e) {
            console.error(`Error creating user; error=${e}`);
            return;
          }
        } else {
          console.debug(`Logged out`);
          setUserRecord(undefined);
          setReady(true);
        }
      }
    );
    return () => {
      console.debug(`useAppAuth: Unsubscribing...`);
      unsubscribe();
    };
  }, [auth, database]);

  return [ready, userRecord];
};
