import { Database } from "../database/FirestoreDatabase";
import { ChannelId, UserId } from "../database/Types";
import { CreateNewPostSuggestion, NavigateToPostSuggestion } from "./Types";

export const getSuggestions = async (
  userId: UserId,
  channelId: ChannelId,
  titlePrefix: string,
  database: Pick<Database, "getAllPostsByTitlePrefix">
) => {
  const matches = await database.getAllPostsByTitlePrefix(channelId, titlePrefix);

  const exactMatchExists =
    matches.filter((s) => s.post.title === titlePrefix && s.user.uid === userId)
      .length !== 0;

  const createNewPostSuggestions: CreateNewPostSuggestion[] = exactMatchExists
    ? []
    : [{ action: "createNewPost", title: titlePrefix }];

  const matchingSuggestions: NavigateToPostSuggestion[] = matches.map((s) => ({
    title: s.post.title,
    postId: s.post.id!,
    authorId: s.user.uid,
    channelId: s.post.channelId,
    authorUsername: s.user.username,
    action: "navigateToPost",
  }));

  return [...createNewPostSuggestions, ...matchingSuggestions];
};
