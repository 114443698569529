import { createMuiTheme } from "@material-ui/core";
import { pink, purple, red, teal, indigo } from "@material-ui/core/colors";

export const createTheme = (color: string) =>
  createMuiTheme({
    typography: {
      h1: {
        fontWeight: 600,
        fontSize: "38px",
      },
      h2: {
        fontWeight: 600,
        fontSize: "30px",
      },
      h3: {
        fontWeight: 700,
        fontSize: "26px",
      },
      h4: {
        fontSize: "24px",
      },
      h5: {
        fontSize: "20px",
      },
      h6: {
        fontWeight: 700,
        fontSize: "20px",
      },
    },
    palette: {
      background: {
        default: "#fff",
      },
      primary: {
        main: color,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 680,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });

export const themes = {
  default: createTheme(pink["500"]),
  pink: createTheme(pink["500"]),
  teal: createTheme(teal["500"]),
  purple: createTheme(purple["500"]),
  indigo: createTheme(indigo["500"]),
  red: createTheme(red["500"]),
};

export const previewTheme = createMuiTheme({
  typography: {
    h1: {
      fontWeight: 600,
      fontSize: "76px",
    },
    h2: {
      fontWeight: 600,
      fontSize: "60px",
    },
    h3: {
      fontWeight: 700,
      fontSize: "52px",
    },
    h4: {
      fontSize: "48px",
    },
    h5: {
      fontSize: "40px",
    },
    h6: {
      fontWeight: 700,
      fontSize: "40px",
    },
    subtitle1: {
      fontSize: "32px",
    },
    body1: {
      fontSize: "32px",
    },
  },
  palette: {
    background: {
      default: "#fff",
    },
    primary: {
      main: pink["500"],
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 680,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
