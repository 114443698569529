import { Redirect } from "react-router-dom";
import { useGlobalStyles } from "../styles/GlobalStyles";
import { CircularProgress } from "@material-ui/core";
import { Paths } from "../services/Paths";

export const DelayLoadWrapper = (props: {
  ready: boolean;
  error: boolean;
  render: () => JSX.Element;
  color?: "secondary" | "primary" | "inherit" | undefined;
  label?: string;
}) => {
  const { ready, error, render, color, label } = props;
  const globalStyles = useGlobalStyles();
  if (ready) {
    return render();
  } else if (error) {
    console.debug(`Error loading data for component; error=${error}`);
    return <Redirect to={Paths.root()} />;
  } else {
    label && console.log(`DelayLoadWrapper at ${label}`);
    return (
      <CircularProgress
        color={color}
        className={globalStyles.loadingIndicator}
      />
    );
  }
};
