import React from "react";
import { Typography, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useGlobalStyles } from "../styles/GlobalStyles";
import { Paths } from "../services/Paths";
import { isEmptyDoc } from "./editor/Utils";
import { postPreviewFromStart } from "../services/PostHelpers";
import { DateTime } from "luxon";
import { CompactViewer } from "./editor/Editor";
import { PostRecord } from "../services/database/Types";

export const ProfilePostCard = (props: { post: PostRecord }) => {
  const globalClasses = useGlobalStyles();
  const { post } = props;
  const dt = DateTime.fromJSDate(post.updatedAt || new Date());
  let preview = undefined;
  if (!isEmptyDoc(post.body)) {
    const [previewDoc, truncatedStart, truncatedEnd] = postPreviewFromStart(
      post.body
    );
    preview = (
      <>
        {truncatedStart && <Typography>⋯</Typography>}
        <CompactViewer body={previewDoc} />
        {truncatedEnd && <Typography>⋯</Typography>}
      </>
    );
  }

  const anonPost = post.title === "IGNORE";

  return (
    <Paper
      className={globalClasses.postPreviewCard}
      elevation={0}
      key={post.id}
    >
      <Typography
        variant="body1"
        className={globalClasses.cardTitle}
        gutterBottom
      >
        {!anonPost && (
          <Link
            className={globalClasses.link}
            to={Paths.post(post.channelId, post.id!)}
            style={{ display: "inline" }}
          >
            {post.title} ·
          </Link>
        )}
        <span
          className={
            !anonPost
              ? globalClasses.cardTitleDatePartNoPadding
              : globalClasses.cardTitleDatePartNoPadding
          }
        >
          <Link
            className={globalClasses.link}
            to={Paths.post(post.channelId, post.id!)}
            style={{ display: "inline" }}
          >
            {dt.toFormat("MMM d")}
          </Link>
        </span>
      </Typography>
      {preview ? (
        <>{preview}</>
      ) : (
        <Typography
          paragraph={false}
          variant="body2"
          className={globalClasses.cardEmptyPreview}
        >
          <em className={globalClasses.cardNoContent}>Empty post</em>
        </Typography>
      )}
    </Paper>
  );
};
