import {
  Box,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { DateTime } from "luxon";
import { Fragment } from "react";
import useSWR from "swr";
import { SwrPaths } from "../services/SwrPaths";
import { Database } from "../services/database/FirestoreDatabase";
import { ChannelRecord, PostId, UserRecord } from "../services/database/Types";
import { PostBody } from "./PostBody";
import { IsDirtyFn } from "./PostView";
import { useMenuState } from "./useMenuState";
import { Link } from "react-router-dom";
import { Paths } from "../services/Paths";

export const ThreadCard = (props: {
  userRecord: UserRecord;
  postId: PostId;
  loggedInUser: UserRecord | undefined;
  channel: ChannelRecord;
  database: Pick<
    Database,
    | "getPost"
    | "updatePost"
    | "deleteThreadPost"
    | "createPost"
    | "getAllPostsByTitlePrefix"
  >;
  registerState: (postId: PostId, registeredFn: IsDirtyFn) => () => void;
}) => {
  const { userRecord, postId, loggedInUser, database, registerState, channel } =
    props;
  const classes = useStyles();
  const userId = userRecord.uid;
  const postResult = useSWR(SwrPaths.post(channel.id, postId), async () =>
    database.getPost(channel.id, postId)
  );

  const readOnly = !(loggedInUser && loggedInUser.uid === userId);

  /**
   * Menu, delete, etc.
   */
  const { menuAnchorEl, onMenuClick, onMenuClose } = useMenuState();
  const handleDelete = async (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    if (!postResult.data) {
      console.error("Post result not ready yet");
      return;
    }
    if (!postResult.data.parentId) {
      console.error(
        `Malformed post: parentId not set; post=${JSON.stringify(
          postResult.data
        )}`
      );
      return;
    }
    onMenuClose();
    await database.deleteThreadPost(
      postResult.data.channelId,
      postId,
      postResult.data.parentId
    );
    console.debug(`Thread post ${postId} deleted`);
  };

  if (postResult.error) {
    console.debug(`Got error; result=${JSON.stringify(postResult)}`);
  }

  if (!postResult.data) {
    return <Skeleton height={"4em"} />;
  } else {
    const postRecord = postResult.data;
    const updatedAt = DateTime.fromJSDate(postRecord.updatedAt || new Date());
    return (
      <>
        <PostBody
          userRecord={userRecord}
          postRecord={postResult.data}
          channel={channel}
          loggedInUser={loggedInUser}
          key={`post_body_${postResult.data.id!}`}
          database={database}
          registerState={registerState}
        />
        <Box className={classes.subtitleCard}>
          <Typography variant="subtitle2" component="div">
            <span>
              <Link
                className={classes.subitleLink}
                to={Paths.post(postResult.data.channelId, postResult.data.id!)}
              >
                {updatedAt.toFormat("h:mm a · MMM d, yyyy")}
              </Link>
            </span>
            {!readOnly && (
              <span className={classes.subtitlePart}>
                <Tooltip title="Delete, settings, and more...">
                  <IconButton size="small" onClick={onMenuClick}>
                    <MoreHorizIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
                {!!menuAnchorEl && (
                  <Menu
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={Boolean(menuAnchorEl)}
                    onClose={onMenuClose}
                  >
                    <MenuItem
                      dense
                      onClick={handleDelete}
                      data-testid={`delete-${postRecord.id}`}
                    >
                      <DeleteIcon
                        fontSize="small"
                        className={classes.subtitleMoreMenuItem}
                      />
                      <span className={classes.subtitleMoreMenuItemText}>
                        Delete
                      </span>
                    </MenuItem>
                  </Menu>
                )}
              </span>
            )}
          </Typography>
        </Box>
      </>
    );
  }
};

export const ThreadSection = (props: {
  userRecord: UserRecord;
  threadPosts: PostId[];
  loggedInUser: UserRecord | undefined;
  channel: ChannelRecord;
  database: Pick<
    Database,
    | "getPost"
    | "updatePost"
    | "deleteThreadPost"
    | "createPost"
    | "getAllPostsByTitlePrefix"
  >;
  registerState: (postId: PostId, registeredFn: IsDirtyFn) => () => void;
}) => {
  const {
    threadPosts,
    database,
    userRecord,
    loggedInUser,
    registerState,
    channel,
  } = props;
  const classes = useStyles();
  const threadPostElements = threadPosts.map((id) => {
    return (
      <Fragment key={`post_${id}`}>
        <Box
          id={`${Paths.threadPostId(id)}`}
          className={classes.threadSeparator}
        ></Box>
        <ThreadCard
          database={database}
          userRecord={userRecord}
          channel={channel}
          postId={id}
          loggedInUser={loggedInUser}
          key={`post_${id}`}
          registerState={registerState}
        />
      </Fragment>
    );
  });
  return <>{threadPostElements}</>;
};

const useStyles = makeStyles((theme) => ({
  subitleLink: {
    textDecoration: "none",
    color: "inherit",
  },
  subtitleCard: {
    color: theme.palette.grey[700],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  card: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  subtitlePart: {
    paddingLeft: theme.spacing(1),
  },
  threadSeparator: {
    minHeight: "1.25em", // really want this to be Xem, proportional to font size
    borderLeft: "solid 3px",
    borderColor: theme.palette.grey[400],
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(0.5),
  },
  subtitleMoreMenuItem: {},
  subtitleMoreMenuItemText: {
    paddingLeft: theme.spacing(1),
  },
}));
