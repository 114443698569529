import * as _ from "lodash";
import { Editor, Node, Path } from "slate";
import { SlateDoc } from "./Types";

export const EMPTY_SLATE_DOC: SlateDoc = [
  {
    children: [
      {
        type: "p",
        children: [{ text: "" }],
      },
    ],
  },
];

export const wrapInLink = (slateDoc: SlateDoc, url: string): Node => ({
  type: "a",
  url: url,
  children: slateDoc,
});

export const wrapInParagraph = (slateDoc: SlateDoc): Node => ({
  type: "p",
  children: slateDoc,
});

export const wrapInTopLevelSlateDoc = (slateDoc: SlateDoc): SlateDoc => [
  {
    children: slateDoc,
  },
];

export const stringToSlateDocNode = (s: string): Node => ({ text: s });

export const stringToTopLevelSlateDoc = (s: string): SlateDoc =>
  wrapInTopLevelSlateDoc([wrapInParagraph([stringToSlateDocNode(s)])]);

export const slateNodeToString = (text: SlateDoc): string =>
  Node.string(text[0]);

export const EDITABLE_TYPOGRAPHY_TEXT_NODE_PATH = [0, 0, 0];

// TODO: This should probably be converted to a TSX function, so that the
// preview is a React component. Code from Rendering.tsx can probably be used to
// generate the preview.
export const richTextStringPreview = (
  slateDoc: SlateDoc
): string | undefined => {
  if (!slateDoc || slateDoc.length === 0) {
    return undefined;
  }

  return Array.from(Node.texts(slateDoc[0]), ([text]) => text.text)
    .slice(0, 3)
    .join("\n");
};

export const wrapNodeAsDoc = (node: Node) => {
  return wrapNodesAsDoc([node]);
};

export const wrapNodesAsDoc = (nodes: Node[]) => {
  return [{ children: nodes }];
};

/**
 * The idea here is: for each level in the path, see if there is a next node.
 * If not, we must be at the final node for the given level.
 */
export const isLastBlock = (editor: Editor, path: Path) => {
  for (let i = path.length; i > 0; i--) {
    const curr = path.slice(0, i);
    const next = Path.next(curr);
    if (Node.has(editor, next)) {
      return false;
    }
  }
  return true;
};

// This is not totally correct. It wouldn't "see" mentions etc.
export const isEmptyDoc = (doc: SlateDoc): boolean => {
  return _.isEqual(doc, EMPTY_SLATE_DOC);
};

/**
 * Create a slate doc containing a single line with a single link.
 */
export const linkToSlateDoc = (link: string): SlateDoc => {
  return wrapInTopLevelSlateDoc([
    wrapInParagraph([
      stringToSlateDocNode(""),
      wrapInLink([stringToSlateDocNode(link)], link),
      stringToSlateDocNode(""),
    ]),
  ]);
};
