import { Box, makeStyles, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { Database } from "../services/database/FirestoreDatabase";
import { ChannelId, PostRecord, UserPost } from "../services/database/Types";
import { findMentionsInPosts, MentionContext } from "../services/PostHelpers";
import { SwrPaths } from "../services/SwrPaths";
import { Paths } from "../services/Paths";
import { useGlobalStyles } from "../styles/GlobalStyles";
import { CompactViewer } from "./editor/Editor";

const useStyles = makeStyles((theme) => ({
  mentionsHeading: {
    fontWeight: 500,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  mentionCard: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    "&:hover": {
      borderRadius: "4px",
      borderColor: theme.palette.action.selected,
      backgroundColor: theme.palette.action.selected,
    },
  },
}));

const MentionCard = ({
  mentionContext,
  channelId,
  database,
}: {
  mentionContext: MentionContext;
  channelId: ChannelId;
  database: Pick<Database, "getPost">;
}) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const rootPostId =
    mentionContext.post.post.parentId || mentionContext.post.post.id!;
  const rootPostResult = useSWR(
    SwrPaths.post(channelId, rootPostId),
    async () => {
      if (rootPostId === mentionContext.post.post.id!) {
        return mentionContext.post.post;
      } else {
        return database.getPost(channelId, rootPostId);
      }
    }
  );

  const postRecord = mentionContext.post.post;
  const userRecord = mentionContext.post.user;
  const updatedAt = DateTime.fromJSDate(postRecord.updatedAt || new Date());
  const { text, truncatedStart, truncatedEnd } = mentionContext;

  if (!rootPostResult.data) {
    return <Skeleton height={"2em"} />;
  } else {
    const rootPostRecord = rootPostResult.data;
    const anonPost = rootPostRecord.title === "IGNORE";
    return (
      <Box className={classes.mentionCard}>
        <Typography variant="body1" className={globalClasses.mentionCardTitle}>
          {!anonPost && (
            <span>
              <Link
                className={globalClasses.link}
                to={Paths.post(channelId, rootPostId)}
                style={{ display: "inline" }}
              >
                {rootPostRecord.title}
              </Link>
            </span>
          )}
          <span
            className={
              !anonPost
                ? globalClasses.cardTitleDatePart
                : globalClasses.cardTitleDatePartNoPadding
            }
          >
            {userRecord.displayName} ·
          </span>
          <span className={globalClasses.cardTitleDatePartNoPadding}>
            {" "}
            <Link
              className={globalClasses.link}
              to={Paths.post(channelId, rootPostId)}
              style={{ display: "inline" }}
            >
              {updatedAt.toFormat("MMM d")}
            </Link>
          </span>
        </Typography>
        {truncatedStart && <Typography>⋯</Typography>}
        <CompactViewer body={text} />
        {truncatedEnd && <Typography>⋯</Typography>}
      </Box>
    );
  }
};

export const MentionsSection = ({
  mentionedPost,
  mentionPosts,
  channelId,
  database,
}: {
  mentionedPost: PostRecord;
  mentionPosts: UserPost[];
  channelId: ChannelId;
  database: Pick<Database, "getPost">;
}) => {
  const classes = useStyles();
  const mentionedPostIds = [mentionedPost.id!];
  mentionedPost.threadPosts.forEach((threadPost) => {
    mentionedPostIds.push(threadPost);
  });
  const mentionContexts = findMentionsInPosts(mentionPosts, mentionedPostIds);
  const mentionCards = mentionContexts.map((mentionContext) => {
    const key = `mention_card_${
      mentionContext.post.post.id
    }_${mentionContext.path.join(".")}`;
    return (
      <MentionCard
        key={key}
        channelId={channelId}
        mentionContext={mentionContext}
        database={database}
      />
    );
  });
  return (
    <>
      <Box>
        <Typography variant="h6" className={classes.mentionsHeading}>
          Mentions
        </Typography>
      </Box>
      <>{mentionCards}</>
    </>
  );
};
