import { UserId, ChannelId, PostId } from "./database/Types";

export class SwrPaths {
  static user(userId: UserId) {
    return `/users/${userId}`;
  }
  static channel(channelId: ChannelId) {
    return `/channels/${channelId}`;
  }
  static post(channelId: ChannelId, postId: PostId) {
    return `/channels/${channelId}/posts/${postId}`;
  }
  static channelPosts(channelId: ChannelId, pageId: number) {
    return `/channels/${channelId}/posts/page/${pageId}`;
  }
  static userPosts(userId: UserId) {
    return `/users/${userId}/posts`;
  }
  static userChannels(userId: UserId) {
    return `/users/${userId}/channels`;
  }
  static postMentions(channelId: ChannelId, postId: PostId) {
    return `/channels/${channelId}/posts/${postId}/mentions`;
  }
  static whitelist(email: string) {
    return `/whitelist/${email}`;
  }
}
