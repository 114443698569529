import { SlateDoc } from "../../components/editor/Types";
import { EMPTY_SLATE_DOC } from "../../components/editor/Utils";
import { customAlphabet } from "nanoid";

/**
 * Persistent types; the database schema.
 */

export type UserId = string;

export interface UserRecord {
  uid: UserId;
  displayName: string;
  username: string;
  description?: string;
  mainChannelId?: string;
}

export type PostId = string;

export interface PostRecord {
  id?: PostId;
  updatedAt?: Date;
  authorId: UserId;
  body: SlateDoc;
  title: string;
  mentions: PostId[];
  followCount: number;
  threadPosts: PostId[];
  parentId?: PostId | null;
  channelId: ChannelId;
}

export type ChannelId = string;

export type ThemeName = "default" | "pink";

export interface ChannelRecord {
  id: ChannelId;
  name: string;
  updatedAt?: Date;
  owners: UserId[];
  visibility: string;
  theme: ThemeName;
}

/**
 * Model classes not directly represented in the database.
 */

export interface UserPost {
  user: UserRecord;
  post: PostRecord;
}

/**
 * Helpers, named constructors etc.
 */

export const PostRecords = {
  mainPostWithBody: (
    channelId: ChannelId,
    id: PostId,
    authorId: UserId,
    body: SlateDoc,
    title: string
  ): PostRecord => {
    return {
      id,
      updatedAt: new Date(),
      authorId,
      body,
      title,
      mentions: [],
      followCount: 0,
      threadPosts: [],
      parentId: undefined,
      channelId,
    };
  },
  emptyThreadPost: (
    userId: UserId,
    postId: PostId,
    parentPostId: PostId,
    channelId: ChannelId
  ): PostRecord => {
    return {
      id: postId,
      updatedAt: new Date(),
      authorId: userId,
      body: EMPTY_SLATE_DOC,
      title: "IGNORE", // TODO: fix should be optional
      mentions: [],
      followCount: 0,
      threadPosts: [],
      parentId: parentPostId,
      channelId: channelId,
    };
  },
};

const nanoid = customAlphabet(
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
  21
);

export const idgen = () => {
  return nanoid();
};
