import { Container, CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { PropsWithChildren } from "react";
import { themes } from "../styles/Theme";

export const PeripheralViewContainer = ({
  children,
}: PropsWithChildren<{}>) => {
  return (
    <MuiThemeProvider theme={themes["default"]}>
      <CssBaseline />
      <Container maxWidth="sm">
        <div>{children}</div>
      </Container>
    </MuiThemeProvider>
  );
};
