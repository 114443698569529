import firebase from "firebase";
import { Database } from "../services/database/FirestoreDatabase";
import { UserRecord } from "../services/database/Types";
import { SignInView } from "./SignInView";

export const SignUpView = ({
  firebaseAuth,
  loggedInUser,
  database,
}: {
  firebaseAuth: firebase.auth.Auth;
  loggedInUser: UserRecord | undefined;
  database: Pick<Database, "getWhitelisted">;
}) => {
  return (
    <SignInView
      firebaseAuth={firebaseAuth}
      loggedInUser={loggedInUser}
      database={database}
      signInTitleOverride="Sign up with email"
    />
  );
};
