import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Box, Button, makeStyles } from "@material-ui/core";

export const PostActions = (props: {
  addButtonDisabled: boolean;
  onAddThreadPost: () => void;
}) => {
  const { addButtonDisabled, onAddThreadPost } = props;
  const classes = useStyles();
  const handleAddButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onAddThreadPost();
  };
  return (
    <Box className={classes.addPostRow}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutlineIcon />}
        disabled={addButtonDisabled}
        onClick={handleAddButtonClick}
      >
        Add
      </Button>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  addPostRow: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));
