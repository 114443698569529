export const WELCOME_DOC = [
  {
    children: [
      {
        children: [
          { text: "Get started at " },
          {
            postId: "567gX0x1x76ZkYNcOkOhn",
            authorId: "6kMSYzWjT8NZEBTAhJuHcpZi2VR2",
            exists: true,
            channelId: "QYIgDErpnPD5Lta25JRH",
            children: [{ text: "" }],
            value: "Getting Started in Reweave",
            authorUsername: "reweave-help@rockfish.xyz",
            type: "mention",
          },
          { text: "." },
        ],
        type: "p",
      },
    ],
  },
];
