import {
  MentionNodeData,
  MentionSelect,
  pipe,
  useMention,
} from "@blfrg.xyz/slate-plugins";
import { Typography } from "@material-ui/core";
import { EditablePlugins } from "@udecode/slate-plugins-core";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { createEditor, Operation } from "slate";
import { ReactEditor, Slate } from "slate-react";
import { LogEventFn } from "../../services/Analytics";
import { useGlobalStyles } from "../../styles/GlobalStyles";
import { themes } from "../../styles/Theme";
import {
  compactViewerOptions,
  Options,
  postEditorOptions,
  postViewerOptions,
} from "./Options";
import * as EditorPlugins from "./Plugins";
import { SlateDoc } from "./Types";

export type RichTextEditorMentionTypeaheadComponents = {
  mentionables: MentionNodeData[];
  onMentionSearchChanged: (search: string) => void;
  onMentionAdded: (option: MentionNodeData) => void;
  mentionableElementFn?: (option: MentionNodeData) => JSX.Element;
};

export type EditorProps = {
  body: SlateDoc;
  readOnly?: boolean;
  options?: Options;
  onChange: (newBody: SlateDoc) => void;
  mentionTypeaheadComponents?: RichTextEditorMentionTypeaheadComponents;
  logEvent?: LogEventFn;
};

const didOpsAffectContent = (ops: Operation[]): boolean => {
  return ops.some((op) => !Operation.isSelectionOperation(op));
};

export type EditorImperativeHandle = {
  focusEditor: () => void;
  blurEditor: () => void;
};

const addMentionsClickHandler = (options: Options, logEvent: LogEventFn) => {
  if (!options.mentions) {
    options.mentions = {};
  }
  if (!options.mentions.mention) {
    options.mentions.mention = {};
  }
  if (!options.mentions.mention.rootProps) {
    options.mentions.mention.rootProps = {};
  }
  options.mentions.mention.rootProps.onClick = ({
    value,
  }: {
    value: string;
  }) => {
    logEvent("click_inline_mention", { value });
  };
};

export const Editor = forwardRef<EditorImperativeHandle, EditorProps>(
  (props, ref) => {
    const {
      logEvent = (name, params) => {},
      options,
      mentionTypeaheadComponents,
      onChange,
      body,
      readOnly,
    } = props;
    const editorOptions =
      options || (readOnly ? postViewerOptions : postEditorOptions);

    // A little hacky, but fine.
    addMentionsClickHandler(editorOptions, logEvent);

    const [plugins, decorator] = useMemo(
      () => EditorPlugins.createPlugins(editorOptions),
      [editorOptions]
    );

    // We use a ref here because the editor instance must not change across reloads, but
    // hot reload in react will force even useMemo to reload.
    const editorRef = useRef<ReactEditor>();
    if (!editorRef.current) {
      editorRef.current = pipe(createEditor(), decorator);
    }

    const editor = editorRef.current;

    const globalClasses = useGlobalStyles();

    const {
      mentionables,
      onMentionAdded,
      onMentionSearchChanged,
      mentionableElementFn,
    } = mentionTypeaheadComponents ?? {
      mentionables: [],
      onMentionAdded: (option: MentionNodeData) => {},
      onMentionSearchChanged: (search: string) => {},
      mentionableElementFn: (option) => <Typography>{option.value}</Typography>,
    };

    useImperativeHandle(ref, () => ({
      focusEditor: () => ReactEditor.focus(editor),
      blurEditor: () => ReactEditor.blur(editor),
    }));

    const {
      onAddMention,
      onChangeMention,
      onKeyDownMention,
      search,
      index,
      target,
      values,
    } = useMention(mentionables, onMentionAdded, {
      maxSuggestions: 10,
    });

    useEffect(() => {
      onMentionSearchChanged(search);
    }, [search, onMentionSearchChanged]);

    // Hack to remove padding from the last paragraph.
    useEffect(() => {
      const pEls = document.querySelectorAll(
        "p.MuiTypography-paragraph, blockquote"
      );
      pEls.forEach((el) => {
        if (!el.nextElementSibling) {
          el.classList.add(globalClasses.lastBlock);
        } else {
          el.classList.remove(globalClasses.lastBlock);
        }
        if (!el.previousElementSibling) {
          el.classList.add(globalClasses.firstBlock);
        } else {
          el.classList.remove(globalClasses.firstBlock);
        }
      });
    });

    const handleChange = (newBody: SlateDoc) => {
      if (didOpsAffectContent(editor.operations)) {
        onChange(newBody);
      }
    };

    const onClickMention = (editor: ReactEditor, option: MentionNodeData) => {
      console.debug(`on click mention ${JSON.stringify(option)}`);
      onAddMention(editor, option);
      onMentionAdded(option);
    };

    return (
      <Slate
        editor={editor}
        value={body}
        onChange={(newValue) => {
          handleChange(newValue);
          onChangeMention(editor);
        }}
      >
        <EditablePlugins
          plugins={plugins}
          readOnly={readOnly ?? false}
          placeholder={readOnly ? "Nothing here yet" : "Enter some text"}
          autoFocus
          spellCheck={false}
          onKeyDown={[onKeyDownMention]}
          onKeyDownDeps={[index, search, target, values]}
          className={
            readOnly
              ? globalClasses.readOnlyRichText
              : globalClasses.editableRichText
          }
        />
        {!readOnly && (
          <MentionSelect
            at={target}
            valueIndex={index}
            options={values}
            onClickMention={onClickMention}
            rowElementFn={mentionableElementFn}
            styles={{
              mentionItemSelected: {
                backgroundColor: themes["default"].palette.action.selected,
              },
            }}
          />
        )}
      </Slate>
    );
  }
);

export const CompactViewer = (props: { body: SlateDoc }) => {
  return (
    <Editor
      readOnly={true}
      body={props.body}
      options={compactViewerOptions}
      onChange={(newBody: SlateDoc) => {}}
    />
  );
};
