import firebase from "firebase";
import { PostRecord } from "./Types";

export const PostRecordConverter = {
  toFirestore: (record: PostRecord): firebase.firestore.DocumentData => {
    const firestoreRecord: firebase.firestore.DocumentData = Object.assign(
      {},
      record
    );
    firestoreRecord.caseInsensitiveTitle = firestoreRecord.title.toLowerCase();
    firestoreRecord.parentId = firestoreRecord.parentId || null;
    return firestoreRecord;
  },
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): PostRecord => {
    const data = snapshot.data(options)!;
    return {
      updatedAt: data.updatedAt?.toDate(),
      authorId: data.authorId,
      id: snapshot.id,
      body: data.body,
      title: data.title,
      mentions: data.mentions || [],
      followCount: data.followCount || 0,
      threadPosts: data.threadPosts || [],
      parentId: data.parentId,
      channelId: data.channelId || "ENOTALLOWED",
    };
  },
};