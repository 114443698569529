import { Box, Typography, Tooltip, makeStyles } from "@material-ui/core";
import { useGlobalStyles } from "../../styles/GlobalStyles";

const useStyles = makeStyles((theme) => ({
  prefixPart: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
}));

export type CreateNewAnonPostSearchResultProps = {};

export const CreateNewAnonPostSearchResult = (
  props: CreateNewAnonPostSearchResultProps
) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const prompt = `New anonymous post`;
  return (
    <Typography
      variant="body1"
      paragraph={false}
      className={globalClasses.searchSuggestionLine}
      component="div"
    >
      <Tooltip title={prompt} placement="bottom-start">
        <Box textOverflow="ellipsis" overflow="hidden">
          <span className={classes.prefixPart}>{prompt}</span>
        </Box>
      </Tooltip>
    </Typography>
  );
};
