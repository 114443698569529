import { Redirect, Route, RouteProps } from "react-router-dom";
import { Paths } from "../services/Paths";

export const AuthdRoute = (props: RouteProps & { loggedIn: boolean }) => {
  const { children, loggedIn, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (loggedIn) {
          console.debug(`Logged in user accessing private route ${rest.path}`);
          return <>{children}</>;
        } else {
          console.debug(`Not logged in and attempting to access ${rest.path}`);
          return <Redirect to={Paths.landingPage()} />;
        }
      }}
    />
  );
};
